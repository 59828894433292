import axios from 'axios';
import { customerFetch, updateCustomerInfo } from './customerActions';
import { fetchLbcTokenApi, liffGetProfile, setItemWithExpiry } from '../services/promotion/auth.service';
import { setDynamicRichMenu } from './profileActions';
import { FETCH_AUTH_TOKEN_FAIL, FETCH_AUTH_TOKEN_SUCCESS } from './types';

export const CUSTOMER_FETCH_SUCCESS = 'CUSTOMER_FETCH_SUCCESS';
export const CUSTOMER_FETCH_FAIL = 'CUSTOMER_FETCH_FAIL';

export const fetchLBCAuthToken = () => {
  return async (dispatch) => {
    try {
        const  profile = await liffGetProfile();
        const response = await fetchLbcTokenApi({profile});
        axios.defaults.headers.common['Authorization'] = `Bearer ${response?.token}`;
        let customerData;
        if (response?.customerId && response?.token) {
            customerData = await dispatch(customerFetch (profile.userId));         
        } else {
            customerData = await dispatch(customerFetch(profile.userId, 'generatetoken=true'));
            const value = {
                displayName: customerData?.displayName,
                expiresIn: customerData?.authExpiresIn,
                pictureUrl: customerData?.pictureUrl,
                token: customerData?.authToken,
                userId: customerData?.uid,
                customerId: customerData?._id,
            };
            setItemWithExpiry(value, value.expiresIn);
        }
        if(customerData) {
            dispatch(updateCustomerInfo(profile.userId));
            setDynamicRichMenu(dispatch, customerData);
        }
        dispatch({
            type: FETCH_AUTH_TOKEN_SUCCESS,
        });
        return customerData?.authToken ? customerData?.authToken : response?.token;

    } catch (error) {
      console.error('Error fetching auth token:', error);
      dispatch({
        type: FETCH_AUTH_TOKEN_FAIL,
        payload: error
      });
    }
  };
};
