import variables from './commonConstant.scss';

const commonConstant = {
  // path
  dummyToken: 'dummyToken',
  pathHome: '/',
  pathAbo: '/abo',
  pathFoa: '/foa',
  pathForgot: '/forgot',
  pathMember: '/member',
  pathChangeMID: '/changeMID',
  pathPublic: '/public',
  pathInterest: '/interest',
  pathSuccess: '/success',
  pathLoginSuccess: '/loginsuccess',
  pathQuestion: '/question',
  pathOtp: '/otp',
  pathChoosePayment: '/payment',
  pathChooseCreditCard: '/payment/creditcard',
  pathPaymentConfirm: '/payment/confirm',
  pathChooseBankTransfer: '/payment/transfer',
  pathCreateCreditCard: '/payment/creditcard/create',
  pathConditionBook: '/condition/book',
  pathConditionEBook: '/condition/ebook',
  pathChooseAddress: '/address',
  pathCreateAddress: '/address/create',
  pathPickAddress: '/address/pick',
  pathMySOP: '/sop',
  pathSOPDetail: '/sop/detail',
  pathToSOPOptions: '/sop/options',
  pathToSOPProfileList: '/sop/profile/list',
  pathCart: '/sop/cart',
  pathChooseMag: '/renewal',
  pathConfirmPayment: '/renewal/confirm',
  pathUpgradePhoneChange: '/member-upgrade',
  pathUpgradeOTP: '/member-upgrade/otp',
  pathUpgradeChooseMag: '/member-upgrade/types',
  pathUpgradeConfirm: '/member-upgrade/confirm',
  pathLinkRichmenu: '/linkRichmenu',
  pathPLP: '/sop-product-list',
  pathPDP: '/sop-product-detail',
  pathSolutionPLP: '/sop-solution-list',

  // Promotion Ordering
  pathPromotionAddressList: '/promotion-address',
  pathCreatePromotionAddress: '/promotion-address/create',
  pathCartPromotion: '/cart-promotion',
  pathProductPromotion: '/product-promotion',
  pathRemovePromotion: '/remove-promotion',
  pathPromotionDetail: '/product-detail',
  pathFOARegister: '/promotion-foa-register',
  // Abuddy
  pathAhappy: '/ahappy',
  pathAbuddyContent: '/abuddy-content',
  pathFOAOtp: '/foa-otp',
  pathRedirectSmartReport: '/smart-report',
  pathViewSmartReport: '/view/smart-report',
  //Library profile
  pathLibrary: '/library',
  pathLibraryProfile: '/library/profile',
  pathAdminCategory: '/library/category/admin',
  pathLibraryCategoryForm: '/library/category/form',
  pathLibraryViewContent: '/library/content',
  pathLibraryContentList: '/library/content/list',
  pathLibraryContentForm: '/library/content/form',
  pathLibrarySubContentList: '/library/content/list/subCate',
  pathShareTargetPicker: '/share-target-picker',
  pathTermsConditions: '/health-and-wellness/terms-conditions',
  pathPolicy: '/health-and-wellness/policy',

  //
  pathCoupon: '/coupon',
  //

  //Assesment
  pathAssesment: '/assesment',
  pathInsertW: '/assesment/insertw',
  pathPageAssesment: '/assesment/pageassesment',
  pathPagequestion: '/assesment/pagequestion',
  pathPagefinish: '/assesment/finish',
  pathPagefinishview: '/assesment/finishview',
  pathPageAssessmentScore: '/assesment/score',
  pathPageAbudyassesment: '/assesment/abudyassesment',
  pathPagePdpaasesment: '/assesment/pdpaasesment',

  // health and wellness
  pathHnWAssessmentHome: '/health-and-wellness/assessment-home',
  pathHnWAssessment: '/health-and-wellness/assessment',
  pathHnWAssessmentHistoryLog: '/health-and-wellness/history-log',
  pathHnWAssessmentScore: '/health-and-wellness/score',
  pathHealthnWellnessForAssessment: '/healthAndWellness',

  pathHealthnWellness: '/health-and-wellness',
  pathProfile: '/health-and-wellness/profile',
  pathBMI: '/health-and-wellness/bmi',
  pathHeight: '/health-and-wellness/height',
  pathWeight: '/health-and-wellness/weight',
  pathWaist: '/health-and-wellness/waist',
  pathMuscle: '/health-and-wellness/muscle',
  pathFat: '/health-and-wellness/fat',
  pathBodyKeyMe: 'https://challenge.amway.co.th/bdk-me-challenge-2024',
  pathFoodCalories: '/health-and-wellness/food-calories',
  pathFoodDetail: '/health-and-wellness/food-calories/detail',
  pathSearchHistory: '/health-and-wellness/food-calories/search-history',
  pathGoalSearchHistory:
    '/health-and-wellness/food-calories/goal-search-history',
  pathFoodLogHome: '/health-and-wellness/food-log-home',
  pathFoodLogList: '/health-and-wellness/food-log-home/log-list',
  pathFoodLogCalendar: '/health-and-wellness/food-log-home/food-log-calendar',
  pathHnwHomeCalendar: '/health-and-wellness/hnw-home-calendar',
  pathHnwGoalsHome: '/health-and-wellness/hnw-goals',
  pathHnwGoalsActivity: '/health-and-wellness/hnw-goals/activity',
  pathHnwGoalsWeight: '/health-and-wellness/hnw-goals/weight',
  pathHnwGoalsNutrition: '/health-and-wellness/hnw-goals/nutrition',
  pathHnwGoalsWater: '/health-and-wellness/hnw-goals/water',
  pathHnwGoalsSupplements: '/health-and-wellness/hnw-goals/supplements',
  pathAddMenu: '/health-and-wellness/food-log-home/add-menu',
  //Exercise Log Routes
  pathHnwExerciseLogHome: '/health-and-wellness/exercise-log-home',
  pathHnwExerciseLogDetail: '/health-and-wellness/exercise-log-detail',
  pathHnwExerciseCategoryList: '/health-and-wellness/exercise-listing',
  pathExerciseLogList: '/health-and-wellness/exercise-log-home/log-list',
  pathHnwExerciseLogCalendar:
    '/health-and-wellness/exercise-log-home/exercise-log-calendar',
  pathAccessCamera: '/access-camera',
  pathAddMenuWOSlash: 'health-and-wellness/food-log-home/add-menu',
  pathChallengeQuiz: '/health-and-wellness/challenge-quiz',
  pathChallengeTeamDashboard: '/health-and-wellness/challenge-team-dashboard',
  pathChallengeMap: '/health-and-wellness/challenge-map',
  pathChallengeList: '/health-and-wellness/challenge-list',
  pathChallengeDetail: '/health-and-wellness/challenge-detail',
  pathChallengeMyDashboard: '/health-and-wellness/challenge-my-dashboard',
  pathAccessChallengeCamera: '/access-challenge-camera',
  pathChallengeMapDetail: '/health-and-wellness/challenge-map-detail',
  pathChallengeVideo: '/health-and-wellness/challenge-video',
  pathChallengeReward: '/health-and-wellness/challenge-reward',
  pathBadgeScreen: '/health-and-wellness/badges',
  pathBadgeDetail: '/health-and-wellness/badge-detail',
  pathMyPictures: '/health-and-wellness/challenge-my-pictures',
  pathChallengeBadgeScreen: '/health-and-wellness/challenge-badges',
  pathChallengeBadgeDetail: '/health-and-wellness/challenge-badge-detail',
  pathChallengeVideoPlayer: '/health-and-wellness/challenge-videoplayer',
  pathHealthnWellnessAutoSyncStep: '/health-and-wellness/auto-sync-step',
  pathAmwayClickDeeplink: '/amwayclick-deeplink',

  //path name
  //name
  //word
  // image
  // env
  REGEX_NUMBER: /^\d*\.?\d*$/,

  envCookie: process.env.REACT_APP_DOMAIN_COOKIE,
  googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  //cookie name
  // error
  // label
  DAYS: {
    th: [
      'วันอาทิตย์',
      'วันจันทร์',
      'วันอังคาร',
      'วันพุธ',
      'วันพฤหัส',
      'วันศุกร์',
      'วันเสาร์',
    ],
    en: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ],
  },
  SHORTDAYS: {
    th: ['อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส'],
    en: ['S', 'M', 'Tu', 'W', 'Th', 'F', 'Sa'],
  },
  MONTHS: {
    th: [
      'มกราคม',
      'กุมภาพันธ์',
      'มีนาคม',
      'เมษายน',
      'พฤษภาคม',
      'มิถุนายน',
      'กรกฎาคม',
      'สิงหาคม',
      'กันยายน',
      'ตุลาคม',
      'พฤศจิกายน',
      'ธันวาคม',
    ],
    en: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  },
  SHORTMONTHS: {
    th: [
      'ม.ค.',
      'ก.พ.',
      'มี.ค.',
      'เม.ย.',
      'พ.ค.',
      'มิ.ย.',
      'ก.ค.',
      'ส.ค.',
      'ก.ย.',
      'ต.ค.',
      'พ.ย.',
      'ธ.ค.',
    ],
    en: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
  },

  GENDER: {
    th: {
      FEMALE: 'หญิง',
      MALE: 'ชาย',
    },
    en: {
      FEMALE: 'Female',
      MALE: 'Male',
    },
  },
  MALE: 'male',
  FEMALE: 'female',
};

export default commonConstant;

export const HEALTH_AND_WELLNESS_TYPE = {
  HEIGHT: 'height',
  WEIGHT: 'weight',
  WAIST: 'waist',
  MUSCLE: 'muscle',
  FAT: 'fat',
};

export const routesMap = {
  bmi: 'pathBMI',
  weight: 'pathWeight',
  height: 'pathHeight',
  waist: 'pathWaist',
  fat: 'pathFat',
  muscle: 'pathMuscle',
};

export const BMIRange = {
  low: 18.5,
  high: 22.9,
};

export const BGIMG = {
  default: 'avatar-bg.svg',
  male: 'male.svg',
  female: 'female.svg',
};

export const H_W_VALIDATION = {
  height: {
    pattern: /^(?!0)\d{0,3}(\.\d{0,2})?$/,
  },
  weight: {
    pattern: /^(?!0)\d{0,3}(\.\d{0,2})?$/,
  },
  waist: {
    pattern: /^(?!0)\d{0,2}(\.\d{0,2})?$/,
  },
  muscle: {
    pattern: /^(?!0)\d{0,2}(\.\d{0,2})?$/,
  },
  fat: {
    pattern: /^(?!0)\d{0,2}(\.\d{0,2})?$/,
  },
  setterWithDecimal: {
    pattern: /^\d+(\.\d{0,2})?$/,
  },
  setterDefault: {
    pattern: /^\d+$/,
  },
};

export const H_W_FOOD_LOG = {
  name: {
    pattern: /^[\u0E00-\u0E3E\u0E40-\u0E7Fa-zA-Z0-9\s\Wก-๙_]{1,50}$/,
  }, //allow only alphabets, numbers and special characters with maximum length 50
  weight: {
    pattern: /^(?:[1-9]|[1-9]\d{1,2}|1\d{3}|2000)$/,
  }, //min 1 and max 2000
  calorie: {
    pattern: /^(?:2000|1\d{3}|[1-9]?\d{1,2}|0)$/,
  }, //min 0 max 2000
  quantity: {
    pattern: /^(0*[1-9][0-9]?|100)$/,
  }, //min 1 max 100
  unit: {
    pattern: /^[a-zA-Zก-๙]{1,10}$/,
    // pattern: /^[\u0E01-\u0E3A\u0E40-\u0E5B]*$/,
  }, //three values from which it is to be discussed
  //min 0 max 10
  carbs: {
    pattern: /^(0?|[1-9]\d?|1\d\d|200)$/,
  }, // min 0 and max 200
  protein: {
    pattern: /^(0?|[1-9]\d?|1\d\d|200)$/,
  }, // min 0 and max 200
  fat: {
    pattern: /^(0?|[1-9]\d?|1\d\d|200)$/,
  }, // min 0 and max 200
};

export const HNW_FOOD_LOG = {
  unitMapping: {
    grams: 'กรัม',
    calories: 'แคลอรี',
  },
  labelMapping: {
    waterQuantityUnit: 'มิลลิลิตร',
    waterCardHeader: 'น้ำ',
    coffeeCardHeader: 'ชา กาแฟ',
    sodaCardHeader: 'น้ำหวาน และน้ำอัดลม',
    together: 'รวม:',
    supplementGoalCardHeader: 'เป้าหมายวันนี้',
    overviewBannerHeader: 'ข้อมูลโภชนาการ',
  },
  categoryMapping: {
    '66099393f6204117109a34ad': 'supplement',
    '66099393f6204117109a34a9': 'food',
  },
  routeMapping: {
    referrer: 'foodlogHome',
    routePath: 'pathFoodLogHome',
  },
};

export const HEIGHT_WEIGHT_POP_UP = {
  TITLE: 'กรุณาระบุข้อมูลสุขภาพ',
  DESCRIPTION: {
    line1: 'รายละเอียดสุขภาพของคุณ เป็นข้อมูลพื้นฐาน',
    line2: 'ที่เราต้องนำมาคำนวน เพื่อให้คำแนะนำ การดูแล',
    line3: 'สุขภาพที่ดีที่สุดให้แก่คุณ',
  },
};

export const REGEX_NUMBER = /^\d*\.?\d*$/;

export const bmiScoreValue = [
  '',
  'A. Less than 18.5',
  'B. 18.5-22.9',
  'C. 23.0-24.9',
  'D. 25.0-29.9',
  'E. More than 29',
];

export const waistlineScoreValue = [
  'A. Male, less than 35',
  'B. Male, 35-38',
  'C. Male, more than 38',
  'D. Female, less than 31',
  'E. Female, 31-35',
  'F. Female, more than 35',
];

export const policyTermsList = [
  'น้ำหนัก',
  'ส่วนสูง',
  'เส้นรอบเอว',
  'ค่าไขมัน',
  'มวลกล้ามเนื้อ',
  'ดัชนีมวลกาย (BMI)',
  'อายุ',
  'เพศ',
  'ข้อมูลแบบประเมินสุขภาพ',
  'พฤติกรรมการรับประทานอาหาร',
  'ข้อมูลอาหาร',
];

export const policyTitle = {
  home_policy: 'home_page_privacy',
};

export const HNW_ASSESSMENT_PATH_KEY = 'pathHnWAssessmentHome';
export const ASSESSMENT_PATH_KEY = 'pathAssesment';
export const ASSESSMENT_TOTAL_QUESTION = 25;
export const ASSESSMENT_QUESTION_TEMPLATE = {
  HealthInfo: {
    progress_tracker: 0,
    question_count: 1,
    header: 'พื้นฐาน',
    icon: 'health',
    title: 'พื้นฐาน',
    colorName: 'green',
    productTag: 'พื้นฐาน',
  },
  Activities: {
    progress_tracker: 1,
    question_count: 2,
    header: 'กิจกรรม',
    icon: 'activity',
    title: 'กิจกรรม',
    colorName: 'blue',
    productTag: 'กิจกรรม',
  },
  Attitude: {
    progress_tracker: 2,
    question_count: 1,
    header: 'ทัศนคติ',
    icon: 'attitude',
    title: 'ทัศนคติ',
    colorName: 'yellow',
    productTag: 'ทัศนคติ',
  },
  FoodHabits: {
    progress_tracker: 3,
    question_count: 2,
    header: 'อาหาร และนิสัยการรับประทาน',
    icon: 'food',
    title: 'ประเภทอาหาร และนิสัยการรับประทาน',
    colorName: 'light_green',
    productTag: 'การกิน',
  },
  Sleep: {
    progress_tracker: 4,
    question_count: 1,
    header: 'การนอน',
    icon: 'sleep',
    title: 'การนอน',
    colorName: 'cyan',
    productTag: 'การนอน',
  },
  Stress: {
    progress_tracker: 5,
    question_count: 1,
    header: 'ความเครียด',
    icon: 'stress',
    title: 'ความเครียด',
    colorName: 'orange',
    productTag: 'ความเครียด',
  },
  Others: {
    progress_tracker: 6,
    question_count: 1,
    header: 'ความสนใจเฉพาะด้าน',
    icon: 'others',
    title: '',
    colorName: 'pink',
    productTag: 'ความสนใจ',
  },
};

export const SEARCH_BAR_PLACEHOLDER = 'ค้นหาเมนูอาหาร เช่น โปรตีนเบอร์รี่';

export const GENDER = {
  male: 'ชาย',
  female: 'หญิง',
};
export const DEFAULT_HOME_PAGE = {
  header: 'หน้าหลัก',
  footer_title: 'ค้นหาไลฟ์สไตล์แบบไหนที่ใช่คุณ',
  points: 'จำนวนคำถาม',
  time: 'ใช้เวลาประมาณ',
  continue_title: 'ยินดีต้อนรับกลับมา',
  retry_title: 'คุณแน่ใจใช่ไหม?',
  continue_subtitle: 'คุณสามารถทำต่อ หรือ เริ่มต้นใหม่ได้เลย',
  retry_subtitle:
    'คำตอบที่คุณตอบไปก่อนหน้า จะถูกลบข้อมูล และต้องเริ่มต้นทำใหม่',
  start_btn: 'เริ่มทำเลย!',
  result_btn: 'ผลลัพธ์',
  retry_btn: 'เริ่มทำเลย!',
  first_cta_text: 'เริ่มทำใหม่ตั้งแต่ต้น',
  second_cta_text: 'ทำต่อจากครั้งที่แล้ว',
  second_cta_done_text: 'กลับไปหน้าผลลัพท์',
};
export const DEFAULT_ASSESSMENT_DESCRIPTION = {
  header: '6 LIFESTYLE KEY',
  subheader: 'ค้นหาไลฟ์สไตล์แบบไหนที่ใช่คุณ',
  title: '6 Lifestyle Key คืออะไร?',
  subtitle: `ตามหลักบอดี้คีย์6ไลฟ์สไตล์โดยเริ่มตั้งแต่ กิจกรรม คุณควรเริ่มที่จะเริ่มออกกำลังกายเบาๆให้มากขึ้นผนวกไปกับการออกกำลังกายแบบมีแรงต้าน ให้มากขึ้นประมาณ 3-5ครั้งต่อสัปดาห์การออกกำลังกายแบบมีแรงต้านจะทำให้ร่างกายของคุณมีความแข็งแรง คงทนมากขึ้นพร้อมทั้งเป็นการสร้างกล้ามเนื้อไปในตัวโดยกล้ามเนื้อของเราจะทำงานเสมือนเตาเผาผลาญที่ช่วยให้ร่างกายเราเผาผลาญพลังงานได้มากยิ่งขึ้นในด้านของ ทัศนคติคุณมีทัศนคติที่ยังไม่ได้ดีมากซึ่งทัศนคติที่ดีแบบ Growth Mindset เป็นสิ่งจำเป็นอย่างยิ่งในการลดน้ำหนักหรือทำตามเป้าหมายอื่นๆในชีวิตของคุณทางด้าน ประเภทอาหารและ นิสัยการรับประทาน
  ในด้านนี่คุณยังมีพฤติกรรมที่ยังต้องปรับปรุงด้วยเหตุนี้คุณควรเริ่มดูแลตัวเองมากขึ้นโดยเริ่มทานอาหารโปรตีนสูง และลดปริมาณอาหาร/เครื่องดื่มที่มีน้ำตาลออกไปน้ำตาลในที้นี้ยังรวมถึงน้ำตาลในผลไม้ที่หากคุณรับประทานมากเกินไปก็จะส่งผลเสียต่อร่างกายคุณได้ในด้านการนอนการนอนเป็นเรื่องสำคัญของร่างกายคุณควรเริ่มพัฒนาการนอนของตัวเองให้ได้อย่างน้อย 7-9 ชั่วโมงและสุดท้าย ความเครียดในส่วนของความเครียดนั่นการที่คุณมีความเครียดที่มากเกินไปจะส่งผลต่อระดับ Cortisolที่เพิ่มสูงขึ้นซึ่งอาจจะมีผลต่อพฤติกรรมต่างๆของคุณได้ อาทิ เช่น โหยอาหาร หงุดหงิดง่าย และทำให้คุณไม่มีความสุขในการดำรงชีวิต`,
  btn: 'เข้าใจแล้ว',
};

export const DEFAULT_HISTORY_LOG = {
  header: 'ประวัติการทำแบบประเมิน',
};
export const DEFAULT_ASSESSMENT_PROGRESS_SCREEN = {
  header: 'ผลลัพท์การประเมิน',
  btn1: 'ประวัติ',
  btn2: 'ประเมินใหม่',
  title: 'คุณแน่ใจใช่ไหม?',
  subtitle: 'คำตอบที่คุณตอบไปก่อนหน้า จะถูกลบข้อมูล และต้องเริ่มต้นทำใหม่',
  btnTitle1: 'เริ่มทำใหม่ตั้งแต่ต้น',
  btnTitle2: 'กลับหน้าผลลัพท์',
};
export const DEFAULT_ASSESSMENT_NOTCOMPLETED_SCREEN = {
  header: 'ผลลัพท์การประเมิน',
  btn1: 'ทำต่อเลย',
  contentHeader: 'คุณตอบคำถามแล้ว',
  subtitle1: 'ทำต่อเพื่อค้นหา',
  subtitle2: 'ไลฟ์สไตล์ที่ใช่คุณ',
};
export const DEFAULT_SCORE_RETRY_MODAL = {
  title: 'คุณแน่ใจใช่ไหม?',
  subtitle: 'คำตอบที่คุณตอบไปก่อนหน้า จะถูกลบข้อมูลและต้องเริ่มต้นทำใหม่',
  btnTitle1: 'เริ่มทำใหม่ตั้งแต่ต้น',
  btnTitle2: 'กลับหน้าผลลัพท์',
};
export const DEFAULT_BUTTON_TITLE = {
  btn_title: 'ถัดไป',
  btn_title2: 'ดูผลลัพธ์ของคุณ',
};
export const multiplyFactorObject = [
  { id: 1, value: 1.0 },
  { id: 2, value: 1.5 },
  { id: 3, value: 2.0 },
  { id: 4, value: 2.5 },
];

export const dailyNutrition = [
  {
    name: 'carbohydratePerDay',
    label: 'คาร์โบไฮเดรต',
    totalKeyName: 'totalCarbs',
    maxKeyName: 'targetCarbs',
  },
  {
    name: 'proteinPerDay',
    label: 'โปรตีน',
    totalKeyName: 'totalProtein',
    maxKeyName: 'targetProtein',
  },
  {
    name: 'fatPerDay',
    label: 'ไขมัน',
    totalKeyName: 'totalFat',
    maxKeyName: 'targetFat',
  },
];

export const nutritionValues = [
  { name: 'carbs', label: 'คาร์โบไฮเดรต' },
  { name: 'protein', label: 'โปรตีน' },
  { name: 'fat', label: 'ไขมัน' },
];

export const ASSESSMENT_STATUS = {
  done: 'DONE',
  pending: 'PENDING',
};

export const LABEL_ML = 'มล.';
export const LABEL_WEIGHT = 'น้ำหนัก';
export const LABEL_CALORIE = 'แคลอรี';
export const LABEL_CARBS = 'คาร์โบไฮเดรต';
export const LABEL_PROTEIN = 'โปรตีน';
export const LABEL_FAT = 'ไขมัน';
export const LABEL_UNIT_GRAM = 'กรัม';
export const LABEL_MENU = 'เมนูอาหาร';
export const LABEL_DIET_SUPPLEMENTS = 'ผลิตภัณฑ์เสริมอาหาร';
export const LABEL_TOTAL_CALORIES = 'แคลอรีรวม';
export const LABEL_MINUTES = 'นาที';
export const LABEL_CALORIES = ' แคลอรี';
export const LABEL_METABOLISM = 'การเผาผลาญ';
export const LABEL_EXERCISE_TIME = 'เวลาออกกำลังกาย';

export const INPUT_PLACEHOLDER = 'กรุณาระบุ';
export const LABEL_EN_DAY_CALENDAR_TAB = 'Day';
export const LABEL_EN_WEEK_CALENDAR_TAB = 'Week';
export const LABEL_EN_MONTH_CALENDAR_TAB = 'Month';

export const LABEL_TH_DAY_CALENDAR_TAB = 'วัน';
export const LABEL_TH_WEEK_CALENDAR_TAB = 'สัปดาห์';
export const LABEL_TH_MONTH_CALENDAR_TAB = 'เดือน';

export const SEARCH_TITLE = 'มาดูกันว่าอาหารของคุณกี่แคลอรี';
export const CATEGORY_TITLE = 'หมวดหมู่';
export const SUPPLEMENT_GOAL_HOME_HEADER_TITLE = 'กลับหน้าหลัก';

export const HEALTH_LOG_HOME_TITLE_TH = 'ปฏิทินการบันทึกสุขภาพ';
export const FOOD_LOG_HOME_TITLE_TH = 'ปฏิทินการบันทึกอาหาร';
export const FOOD_LOG_HEALTH_SUMMARY_RESULT_CARD_HEADER = 'ผลสรุปประจำเดือน';
export const FOOD_LOG_HEALTH_SUMMARY_INFO_CARD_HEADER = 'ข้อมูลโดยสรุป';
export const FOOD_LOG_HEALTH_RECORD_CARD_HEADER = 'การบันทึกประจำเดือน';

export const EXERCISE_LOG_HOME_TITLE_TH = 'ปฏิทินการออกกำลังกาย';

export const GRAPH_TYPE = {
  LINE: 'line',
  BAR: 'bar',
};

export const API_DATE_FORMAT = 'yyyy-MM-dd';

export const GRAPH_TITLE_HEADER1 = 'การกิน';
export const GRAPH_TITLE_HEADER2 = 'การออกกำลังกาย';
export const GRAPH_TITLE_HEADER3 = 'แคลอรี';
export const GRAPH_TITLE_HEADER4 = 'พฤ, 14 มี.ค. 67';
export const GRAPH_TITLE_HEADER_VALUE = '750';

export const GOAL_SETTING_HOME_TITLE_TH = 'เป้าหมายสุขภาพ';
export const ADD_GOAL_BTN_TH = 'เพิ่มเป้าหมาย';
export const GOAL_SETTING_MINUTE_UNIT = 'นาที';
export const GOAL_SETTING_DYNAMIC_POSITION_VALUE_45 = '45';
export const GOAL_SETTING_DYNAMIC_POSITION_VALUE_12 = '12';
export const GOAL_SETTING_KG_PER_WEEK = 'กิโลกรัม/สัปดาห์';

export const LABEL_BUY_FOOD = 'เลือกมื้ออาหาร';
export const LABEL_BREAKFAST = 'อาหารเช้า';
export const LABEL_LUNCH = 'อาหารกลางวัน';
export const LABEL_DINNER = 'อาหารเย็น';

export const DELETE_MODAL_HEADING = 'คุณต้องการลบใช่หรือไม่';
export const DELETE_MODAL_CANCEL = 'เก็บไว้ก่อน';
export const DELETE_BUTTON_CONFIRM = 'ลบ';
export const SAVE_BUTTON_MODAL = 'เลือก';
export const SAVE_MEAL_BUTTON = 'บันทึก';
export const SAVE_BUTTON_COMMON = 'บันทึก';

export const HEALTH_LOG_HEADING = 'บันทึกสุขภาพ';
export const HEALTH_LOG_SUBHEADING =
  'บันทึกข้อมูลสุขภาพของคุณ ได้ทุกวัน พร้อมติดตามข้อมูล ได้แบบเชิงลึก';
export const EMPTY_CARD_HEADING = {
  exercise: 'เพิ่มเป้าหมาย \n การออกกำลังกาย',
  food: 'เพิ่มเป้าหมายการกิน',
  water: 'เพิ่มเป้าหมาย \n การดื่มน้ำ',
};
export const EXCERCISE_CARD_HEADING = 'การเผาผลาญ';
export const FOOD_CARD_HEADING = 'การกิน';
export const WATER_CARD_HEADING = 'ดื่มน้ำ';
export const EXERCISE_CARD_HEADING = 'เผาผลาญ';
export const TIME_CARD_HEADING = 'เวลาออกกำลังกาย';

export const WATER_CIRCLE_START_COLOR = '#64D3FF';
export const WATER_CIRCLE_STOP_COLOR = '#38AFFF';
export const WATER_CIRCLE_BG_ICON = 'water-icon';
export const WATER_CIRCLE_GRADIENT_ID = 'blue';
export const WATER_CIRCLE_GRADIENT_DEGREE = 20;

export const FOOD_CIRCLE_START_COLOR = '#6BC948';
export const FOOD_CIRCLE_STOP_COLOR = '#9FDC7D';
export const FOOD_CIRCLE_BG_ICON = 'food-icon';
export const FOOD_CIRCLE_GRADIENT_ID = 'green';
export const FOOD_CIRCLE_GRADIENT_DEGREE = 180;

export const EXCERCISE_CIRCLE_START_COLOR = '#FF9D66';
export const EXCERCISE_CIRCLE_STOP_COLOR = '#FF9591';
export const EXCERCISE_CIRCLE_BG_ICON = 'burn-icon';
export const EXCERCISE_CIRCLE_GRADIENT_ID = 'red';
export const EXCERCISE_CIRCLE_GRADIENT_DEGREE = 20;

export const PUBLISH_STATUS = {
  PUBLISHED: 'Published',
  UNPUBLISHED: 'UnPublished',
};

export const FOOD_TABS_MAPPING = {
  Food: {
    name: 'Food',
    iconUrl: '/images/healthAndWellness/foodCalories/icons/food.svg',
    iconUrlActive:
      '/images/healthAndWellness/foodCalories/icons/food-active.svg',
    label: LABEL_MENU,
  },
  Supplement: {
    name: 'Supplement',
    iconUrl: '/images/healthAndWellness/foodCalories/icons/food-3.svg',
    iconUrlActive: '/images/healthAndWellness/foodCalories/icons/food-2.svg',
    label: LABEL_DIET_SUPPLEMENTS,
  },
};

export const HOME_CALENDAR_TABS_MAPPING = {
  Day: {
    name: LABEL_EN_DAY_CALENDAR_TAB,
    heading: 'ข้อมูลประจำวัน',
    summary_info_heading: 'ข้อมูลโดยสรุป',
    recording_heading: 'เป้าหมายวันนี้',
    label: LABEL_TH_DAY_CALENDAR_TAB,
  },
  Week: {
    name: LABEL_EN_WEEK_CALENDAR_TAB,
    heading: 'ข้อมูลประจำสัปดาห์',
    summary_result_heading: 'ผลสรุปประจำสัปดาห์',
    summary_info_heading: 'ผลสรุปประจำสัปดาห์',
    summary_info_heading_food: 'ข้อมูลโดยสรุป',
    recording_heading: 'การบันทึกประจำสัปดาห์',
    label: LABEL_TH_WEEK_CALENDAR_TAB,
  },
  Month: {
    name: LABEL_EN_MONTH_CALENDAR_TAB,
    heading: 'ข้อมูลประจำเดือน',
    summary_result_heading: 'ผลสรุปประจำเดือน',
    summary_info_heading_food: 'ข้อมูลโดยสรุป',
    summary_info_heading: 'ผลสรุปประจำเดือน',
    recording_heading: 'การบันทึกประจำเดือน',
    label: LABEL_TH_MONTH_CALENDAR_TAB,
  },
};

export const HOME_GRAPH_XAXIS_MAPPING = {
  DAILY: ['อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส'],
  MONTHLY: commonConstant.SHORTMONTHS.th,
}

export const HOME_CARDS_ROUTES = {
  FOOD: 'food',
  WATER: 'water',
  EXERCISE: 'exercise',
  WEIGHT: 'weight',
  BMI: 'bmi'
}

export const WEIGHT_GRAPH_HEADING = 'น้ำหนัก';
export const LAST_MONTHS_TO_DISPLAY = 7;
export const LABEL_KG = 'กก.';
export const LABEL_CONSTANT = 'คงที่';
export const WEIGHT_GRAPH = 'weight_graph';
export const WEIGHT_LOSS_OVER_TIME = 'การลดน้ำหนักในระยะเวลา';
export const WEIGHT_GAIN_OVER_TIME = 'การเพิ่มน้ำหนักในระยะเวลา';
export const WEIGHT_MAINTAIN_OVER_TIME = 'การรักษาน้ำหนักในระยะเวลา';
export const WEIGHT_GRAPH_MIN_VALUE_OFFSET = 0.1;

export const SEVEN_MONTHS = { number: 7, text: 'เดือน' };
export const ONE_MONTH = { number: 1, text: 'เดือน' };
export const SEVEN_DAYS = { number: 7, text: 'วัน' };

export const GRAPH_TREND = {
  SAME: 'same',
  DIFFERENT: 'different',
  INCREASE: 'increase',
  DECREASE: 'decrease'
}
export const WEIGHT_GRAPH_TYPE = {
  ANNUALLY: 'annually',
  MONTHLY: 'monthly',
  WEEKLY: 'weekly'
}


export const HOME_CALENDAR_XAXIS_MAPPING = {
  Day: ['0', '4', '8', '12', '16', '20'],
  Week: ['อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส'], // sun - sat
  Month: [],
};

export const WEEK_XAXIS_MAPPING_WITH_INDEX = [
  'อา',
  'จ',
  'อ',
  'พ',
  'พฤ',
  'ศ',
  'ส',
];

export const DAY_XAXIS_MAPPING_WITH_HOURS = {
  '0': [0, 1, 2, 3],
  '4': [4, 5, 6, 7],
  '8': [8, 9, 10, 11],
  '12': [12, 13, 14, 15],
  '16': [16, 17, 18, 19],
  '20': [20, 21, 22, 23],
};

export const FOOD_PARAMETERS_MAPPING = [
  {
    name: 'carbs',
    label: LABEL_CARBS,
    iconPath: '/images/healthAndWellness/foodCalories/icons/icon-carb.svg',
    unit: ' ก.',
  },
  {
    name: 'protein',
    label: LABEL_PROTEIN,
    iconPath: '/images/healthAndWellness/foodCalories/icons/icon-protein.svg',
    unit: ' ก.',
  },
  {
    name: 'fat',
    label: LABEL_FAT,
    iconPath: '/images/healthAndWellness/foodCalories/icons/icon-fat.svg',
    unit: ' ก.',
  },
];

export const FOOD_INFO_MAPPING = [
  {
    name: 'quantity',
    label: LABEL_WEIGHT,
    iconPath: '/images/healthAndWellness/foodCalories/icons/scale.svg',
  },
  {
    name: 'calorie',
    label: LABEL_CALORIE,
    iconPath: '/images/healthAndWellness/foodCalories/icons/food-sm.svg',
  },
];

export const routePathKey = {
  foodDetail: 'pathFoodDetail',
  searchHistory: 'pathSearchHistory',
  goalsSearchHistory: 'pathGoalSearchHistory',
  policy: 'pathPolicy',
  foodLog: 'pathFoodCalories',
};

export const NO_FOOD_LIST_TEXT = {
  HEADING: 'ยังไม่มีข้อมูลอาหาร',
  DESCRIPTION_TOP: 'ขออภัยเรากำลังเตรียม',
  DESCRIPTION_BOTTOM: 'ข้อมูลอาหารในหมวดหมู่นี้',
  IMAGE: '/images/healthAndWellness/foodCalories/food-list/no-food.gif',
};

export const NUTRITION_ITEMS = [
  {
    icon: 'add-menu-icon-goal.svg',
    label: 'เทียบกับแคลอรีเป้าหมาย',
    value: '-',
  },
  {
    icon: 'add-menu-icon-carbo.svg',
    label: 'คาร์โบไฮเดรต',
    value: '-%',
  },
  {
    icon: 'add-menu-icon-protein.svg',
    label: 'โปรตีน',
    value: '-%',
  },
  {
    icon: 'add-menu-icon-fat.svg',
    label: 'ไขมัน',
    value: '-%',
  },
];
export const HEALTH_INFO_CARD = {
  headerSummaryInfo: 'ข้อมูลโดยสรุป',
  headerRecordingInfo: 'การบันทึกประจำเดือน',
  headerSummaryCard: 'ผลสรุปประจำเดือน',
};

export const CALENDAR_DATA_LABELS = {
  food: {
    tabLabel: 'อาหาร',
    legendLabel: 'การกิน',
    legendIcon: '/images/healthAndWellness/foodLog/food_dot_legend.svg',
    unit: 'แคลอรี',
    colorDark: variables.foodDark,
    colorLight: variables.foodLight,
  },
  water: {
    tabLabel: 'น้ำ',
    legendLabel: 'การดื่มน้ำ',
    legendIcon: '/images/healthAndWellness/foodLog/water_dot_legend.svg',
    unit: 'มิลลิลิตร',
    colorDark: variables.waterDark,
    colorLight: variables.waterLight,
  },
  exercise: {
    tabLabel: 'แคลอรี',
    legendLabel: 'เผาผลาญ',
    legendIcon: '/images/healthAndWellness/foodLog/exercise_dot_legend.svg',
    unit: 'แคลอรี',
    colorDark: variables.exerciseDark,
    colorLight: variables.exerciseLight,
  },
  time: {
    tabLabel: 'เวลา',
    legendLabel: 'เวลาออกกำลังกาย',
    legendIcon: '/images/healthAndWellness/foodLog/exercise_dot_legend.svg',
    unit: 'นาที',
    colorDark: variables.exerciseDark,
    colorLight: variables.exerciseLight,
  },
  exercise_burn: {
    tabLabel: 'เวลา',
    legendLabel: 'การออกกำลังกาย',
    legendIcon: '/images/healthAndWellness/foodLog/exercise_dot_legend.svg',
    unit: 'แคลอรี',
    colorDark: variables.exerciseDark,
    colorLight: variables.exerciseLight,
  },
};

export const HEALTH_RECORDING_CARD_DETAILS = {
  food: {
    recordUnit: 'ครั้ง',
    recordName: 'การกิน',
    recordImagePath: '/images/healthAndWellness/foodLog/record-food-top.svg',
    bgPath: '/images/healthAndWellness/foodLog/icons/record-food-bg.svg',
    noRecordImagePath:
      '/images/healthAndWellness/foodLog/icons/food-no-record.svg',
    // noRecordTitle: 'เพิ่มข้อมูลอาหาร',
  },
  water: {
    recordUnit: 'ครั้ง',
    recordName: 'การดื่มน้ำ',
    recordImagePath: '/images/healthAndWellness/foodLog/record-water-top.svg',
    bgPath: '/images/healthAndWellness/foodLog/icons/record-water-bg.svg',
    noRecordImagePath:
      '/images/healthAndWellness/foodLog/icons/record-no-water.svg',
    // noRecordTitle: 'เพิ่มการดื่มน้ำ',
  },
  exercise: {
    recordUnit: 'ครั้ง',
    recordName: 'การออกกำลังกาย',
    recordImagePath:
      '/images/healthAndWellness/foodLog/record-exercise-top.svg',
    bgPath: '/images/healthAndWellness/foodLog/icons/record-exercise-bg.svg',
    noRecordImagePath:
      '/images/healthAndWellness/foodLog/icons/exercise-no-record.svg',
    // noRecordTitle: 'เพิ่มข้อมูลอาหาร',
  },
  steps: {
    recordUnit: 'ก้าว',
    recordName: 'นับก้าวเดิน',
    recordImagePath: '/images/healthAndWellness/foodLog/record-steps-top.svg',
    bgPath: '/images/healthAndWellness/foodLog/icons/record-steps-bg.svg',
    noRecordImagePath:
      '/images/healthAndWellness/foodLog/icons/exercise-no-record.svg',
    // noRecordTitle: 'เพิ่มข้อมูลอาหาร',
  },
};

export const HEALTH_SUMMARY_RESULT_CARD_DETAILS = {
  food: {
    icon: '/images/healthAndWellness/foodLog/summary-food-icon.svg',
    name: 'อาหาร',
    unit: 'แคลอรี',
    heading: FOOD_CARD_HEADING,
  },
  water: {
    icon: '/images/healthAndWellness/foodLog/summary-water-icon.svg',
    name: 'น้ำดื่ม',
    unit: 'มิลลิลิตร',
    heading: 'น้ำดื่ม',
  },
  exercise: {
    icon: '/images/healthAndWellness/foodLog/icons/burn.svg',
    name: 'น้ำดื่ม',
    unit: 'แคลอรี',
    heading: EXERCISE_CARD_HEADING,
  },
  time: {
    icon: '/images/healthAndWellness/foodLog/icons/time.svg',
    name: 'น้ำดื่ม',
    unit: 'นาที',
    heading: TIME_CARD_HEADING,
  },
};

export const HEALTH_SUMMARY_INFO_CARD_DETAILS = {
  carbs: {
    name: 'คาร์โบไฮเดตร',
    iconPath: '/images/healthAndWellness/foodLog/icons/icon-carb.svg',
    unit: 'กรัม',
  },
  protein: {
    name: 'โปรตีน',
    iconPath: '/images/healthAndWellness/foodLog/icons/icon-protein.svg',
    unit: 'กรัม',
  },
  fat: {
    name: 'ไขมัน',
    iconPath: '/images/healthAndWellness/foodLog/icons/icon-fat.svg',
    unit: 'กรัม',
  },
  food: {
    name: 'อาหาร',
    iconPath: '/images/healthAndWellness/foodLog/icons/food_circle.svg',
    unit: 'แคลอรี',
  },
  exercise: {
    name: 'เผาผลาญ',
    iconPath: '/images/healthAndWellness/foodLog/icons/exercise_circle.svg',
    unit: 'แคลอรี',
  },
  time: {
    name: 'ระยะเวลา',
    iconPath: '/images/healthAndWellness/foodLog/icons/period_circle.svg',
    unit: 'นาที',
  },
};

export const NO_RESULT_FOUND = {
  header: 'ไม่พบรายการที่บันทึก',
  description: 'ขออภัยเราไม่พบรายการที่คุณบันทึก',
  IMAGE: '/images/healthAndWellness/foodCalories/food-list/no-food.gif',
  food_no_result:
    '/images/healthAndWellness/exercise-log/food-water-no-result.svg',
  exercise_no_result:
    '/images/healthAndWellness/exercise-log/exercise-no-result.svg',
};

export const CALORIE = 'calorie';

export const HEALTH_GOAL_TYPES = {
  WEIGHT: 'weightGoal',
  NUTRITION: 'foodGoal',
  SUPPLEMENT: 'supplementGoal',
  WATER: 'waterGoal',
  ACTIVITY: 'activityGoal',
};

export const DELETE_GOAL_API_MAPPING = {
  [HEALTH_GOAL_TYPES.WEIGHT]: 'weight-goal',
  [HEALTH_GOAL_TYPES.NUTRITION]: 'food-goal',
  [HEALTH_GOAL_TYPES.SUPPLEMENT]: 'supplement-goal',
  [HEALTH_GOAL_TYPES.WATER]: 'water-goal',
  [HEALTH_GOAL_TYPES.ACTIVITY]: 'activity-goal',
};

export const HEALTH_GOAL_TYPES_EXERCISE = 'exerciseGoal';

export const goalRoutesMap = {
  [HEALTH_GOAL_TYPES.WEIGHT]: 'pathHnwGoalsWeight',
  [HEALTH_GOAL_TYPES.NUTRITION]: 'pathHnwGoalsNutrition',
  [HEALTH_GOAL_TYPES.SUPPLEMENT]: 'pathHnwGoalsSupplements',
  [HEALTH_GOAL_TYPES.WATER]: 'pathHnwGoalsWater',
  [HEALTH_GOAL_TYPES.ACTIVITY]: 'pathHnwGoalsActivity',
};

export const EDIT_GOAL_MODAL_TEXT = {
  BACK_BTN: 'ย้อนกลับ',
  MANAGE_GOAL: 'จัดการเป้าหมาย: ',
  DELETE_BUTTON: 'ลบเป้าหมาย',
  EDIT_BUTTON: 'แก้ไขเป้าหมาย',
};

export const EDIT_GOAL_MODAL = {
  [HEALTH_GOAL_TYPES.WEIGHT]: 'น้ำหนัก',
  [HEALTH_GOAL_TYPES.NUTRITION]: 'โภชนาการ',
  [HEALTH_GOAL_TYPES.SUPPLEMENT]: 'ผลิตภัณฑ์เสริมอาหาร',
  [HEALTH_GOAL_TYPES.WATER]: 'ดื่มน้ำ',
  [HEALTH_GOAL_TYPES.ACTIVITY]: 'กิจกรรม',
};

export const HEALTH_GOAL_CARD_CONTENT = {
  [HEALTH_GOAL_TYPES.WEIGHT]: {
    name: 'weight',
    label: 'น้ำหนัก',
    dataName: 'เพิ่มน้ำหนัก',
    dataUnit: 'กิโลกรัม',
    iconPath: '/images/healthAndWellness/goal-setting/icons/weight.svg',
    bgIconPath: '/images/healthAndWellness/goal-setting/goal-setup-weight.svg',
    description: 'ลด/เพิ่ม น้ำหนักเพื่อสร้างหุ่นฟิตในฝัน',
    suffixDescription: 'และมีสุขภาพดีได้ในระยะยาวแบบฉบับคุณ',
  },
  [HEALTH_GOAL_TYPES.NUTRITION]: {
    name: 'nutrition',
    label: 'โภชนาการ',
    dataName: 'การรับแคลอรี',
    dataUnit: 'แคลอรี',
    childDataCarbo: 'คาร์โบไฮเดรต',
    childDataProtien: 'โปรตีน',
    childDataFat: 'ไขมัน',
    childDataUnit: 'กรัม',
    iconPath: '/images/healthAndWellness/goal-setting/icons/food.svg',
    bgIconPath:
      '/images/healthAndWellness/goal-setting/goal-setup-nutrition.svg',
    description: '“You are what you eat” การกินที่ดีมีผลต่อสุขภาพ ของคุณ',
  },
  [HEALTH_GOAL_TYPES.SUPPLEMENT]: {
    name: 'dietary supplements',
    label: 'ผลิตภัณฑ์เสริมอาหาร',
    dataName: '',
    dataUnit: '',
    iconPath: '/images/healthAndWellness/goal-setting/icons/supplement.svg',
    bgIconPath:
      '/images/healthAndWellness/goal-setting/goal-setup-supplement.svg',
    description: 'อาหารเสริมจะช่วยเติมเต็มสารอาหารต่างๆ',
    suffixDescription: 'ที่คุณต้องการอย่างครบถ้วน',
  },
  [HEALTH_GOAL_TYPES.WATER]: {
    name: 'water',
    label: 'ดื่มน้ำ',
    dataName: 'ปริมาณน้ำ',
    dataUnit: 'มิลลิลิตร',
    iconPath: '/images/healthAndWellness/goal-setting/icons/water.svg',
    bgIconPath: '/images/healthAndWellness/goal-setting/goal-setup-drink.svg',
    description: 'ร่างกายเราประกอบไปด้วยน้ำถึง 65%',
    suffixDescription: ' การดื่มน้ำให้เพียงพอ จะทำให้สุขภาพคุณดีโดยรวม',
  },
  [HEALTH_GOAL_TYPES.ACTIVITY]: {
    name: 'activity',
    label: 'กิจกรรม',
    dataName: '',
    dataUnit: '',
    data: [
      // Calories
      { label: 'การเผาผลาญแคลอรี', value: 'targetCalories', unit: 'แคลอรี' },
      // Exercise
      { label: 'ระยะเวลาออกกำลังกาย', value: 'exerciseTime', unit: 'นาที' },
    ],
    iconPath: '/images/healthAndWellness/goal-setting/icons/burn.svg',
    bgIconPath:
      '/images/healthAndWellness/goal-setting/goal-setup-activity.svg',
    description: (newActivity) =>
      newActivity
        ? 'เผาผลาญแคลอรีต่อวัน เพื่อรักษาระดับความฟิต'
        : 'เผาผลาญแคลอรีต่อวัน เพื่อรักษาระดับความฟิต และเลือกออกกำลังกายในสไตล์ที่คุณชอบ',
    exerciseGoal: {
      name: 'exercise',
      label: 'ออกกำลังกาย',
      dataName: '',
      dataUnit: '',
      iconPath: '/images/healthAndWellness/goal-setting/icons/exercise.svg',
      bgIconPath:
        '/images/healthAndWellness/goal-setting/goal-setup-exercise.svg',
      description: 'เลือกออกกำลังกายในสไตล์ที่คุณชอบ',
    },
  },
};

export const LOSE_OR_GAIN_WEIGHT_TH = ['ลดน้ำหนัก', 'เพิ่มน้ำหนัก']; // lose, gain
export const LOSE_WEIGHT_TH = 'ลดน้ำหนัก'; // lose
export const GAIN_WEIGHT_TH = 'เพิ่มน้ำหนัก'; //  gain

export const GOAL_TARGET_TEXT = {
  MAINTAIN_WEIGHT: 'รักษาน้ำหนัก',
  GAIN_WEIGHT: 'เพิ่มน้ำหนัก',
  LOSE_WEIGHT: 'ลดน้ำหนัก',
};

export const WEIGHT_GOAL_MAPPING = {
  [GAIN_WEIGHT_TH]: GRAPH_TREND.INCREASE,
  [LOSE_WEIGHT_TH]: GRAPH_TREND.DECREASE
};

export const GOALS_SUBMIT_BUTTON_TITLE = 'ตั้งเป้าหมาย';

export const GOALS_WEIGHT_DETAILS_TEXT = {
  TARGET: 'เป้าหมาย :',
  DESCRIPTION: 'น้ำหนักต่อสัปดาห์ (กิโลกรัม)',
  TOOLTIP_TOP: 'นับจากวันนี้ คุณจะไปถึงน้ำหนัก',
  TOOLTIP_BOTTOM: 'เป้าหมายได้ใน: ',
  TOOLTIP_TOP_OUTSIDE_LIMIT: 'น้ำหนักเป้าหมายของคุณ',
  TOOLTIP_BOTTOM_OUTSIDE_LOWER_LIMIT: ' ต่ำกว่าเกณฑ์',
  TOOLTIP_BOTTOM_OUTSIDE_UPPER_LIMIT: ' สูงกว่าเกณฑ์',
  SUGGESTION_TOP: 'เพิ่มน้ำหนักเป้าหมาย เพื่อตั้งเป้า ลด/เพิ่ม น้ำหนัก',
  SUGGESTION_BOTTOM: 'กดตัวเลขเพื่อระบุจำนวน',
  SUGGESTION_LAST:
    'น้ำหนักต่อสัปดาห์ เราได้คำนวนการ ลด/เพิ่ม น้ำหนัก ต่อสัปดาห์ที่เหมาะสมกับสุขภาพของคุณ',
  CURRENT_HEADER: 'น้ำหนักปัจจุบัน (กิโลกรัม)',
  TARGET_HEADER: 'น้ำหนักเป้าหมาย (กิโลกรัม)',
};

export const ADD_MENU_FORM = {
  title: 'เพิ่มเมนูใหม่',
  foodCategory: 'หมวดหมู่',
  foodMenu: 'ชื่อเมนู',
  weight: 'น้ำหนัก',
  quantity: 'ปริมาณการบริโภค',
  unit: 'หน่วย',
  calorie: 'แคลอรี',
  carbo: 'คาร์โบไฮเดรต',
  protein: 'โปรตีน',
  fat: 'ไขมัน',
  header: 'ข้อมูลโภชนาการ',
  btn: 'สร้างเมนูใหม่',
  foodCatgeoryLabel: 'เลือกหมวดหมู่',
  foodMenuLabel: 'เช่น ไข่เจียวปูอัดสูตรคุณย่า',
  unitLabl: 'เช่น ถ้วย, จาน, ชาม',
  grams: 'กรัม',
  error: 'โปรดใส่ข้อมูล',
  imgError: 'รูปภาพต้องมีขนาด 1000 x 1000 pixels',
};

export const GOALS_WATER_DETAILS = {
  SETTER_CARD_HEADER: 'ปริมาณน้ำต่อวัน',
  SETTER_CARD_UNIT: 'มิลลิลิตร',
  SETTER_CARD_UNIT_RECOMMENDED: 'มิลลิลิตร (แนะนำ)',
  SETTER_CARD_DEFAULT_VALUE: '2700',
  SETTER_CARD_MINIMUM_VALUE: '100',
  SETTER_CARD_MAXIMUM_VALUE: '50000',
  SETTER_CARD_STEPPER_VALUE: '100',
  TOOLTIP_HEADER: 'คิดเป็นการดื่มน้ำทั้งหมด:',
  TOOLTIP_UNIT: 'แก้ว',
  TOOLTIP_DEFAULT_GLASS_UNIT: 240,
  SETTER_CARD_FOOTER_FIRST:
    'เราสนับสนุนให้ใช้ค่าที่เราแนะนำ เพื่อผลลัพธ์ที่ดีที่สุดของคุณ',
  SETTER_CARD_FOOTER_SECOND: 'อย่างไรก็ตาม, คุณสามารถปรับค่าได้ถ้าต้องการ',
  SUGGESTION_LIST_FIRST: 'เราได้คำนวณปริมาณน้ำต่อวันที่เหมาะสมกับคุณ',
  SUGGESTION_LIST_SECOND_LEFT: 'กด',
  SUGGESTION_LIST_SECOND_RIGHT: 'เพื่อ เพิ่ม/ลด เป้าหมาย',
  SUGGESTION_LIST_THIRD: 'กดตัวเลขเพื่อระบุจำนวน',
};

export const GOAL_SETTER_CARD_DEFAULT = {
  CARD_UNIT: 'มิลลิลิตร',
  CARD_UNIT_RECOMMENDED: 'มิลลิลิตร (แนะนำ)',
  TOOLTIP_UNIT: 'แก้ว',
  CARD_MINIMUM_VALUE: '1',
  CARD_MAXIMUM_VALUE: '50000',
  CARD_STEPPER_VALUE: '100',
  CARD_STEPPER_TYPE_INCREASE: 'INCREASE',
  CARD_STEPPER_TYPE_DECREASE: 'DECREASE',
};

export const GOAL_SUPPLEMENTS_DETAILS = {
  ADD_ITEM_CARD_TITLE: 'รายการอาหารเสริม',
  SUGGESTION_LIST_FIRST: 'เพิ่มพลังงานและสารอาหารให้ร่างกาย',
  SUGGESTION_LIST_SECOND: 'เพื่อสุขภาพที่ดี กับอาหารเสริมชั้นนำ',
  LIST_PAGE_HEADING: 'ดูแลสุขภาพง่ายๆ ได้ทุกวัน!',
  LIST_HEADER_TEXT: 'รายการอาหารเสริม',
  LIST_BUTTON_TEXT: 'เพิ่มรายการ',
  CHECKBOX_EDIT_LABEL: 'เลือกทั้งหมด',
  DELETE_BUTTON_TEXT: 'ลบ',
  MINIMUM_CARD_VALUE: '0',
  MAXIMUM_CARD_VALUE: '9999',
};

export const SUPPLEMENT_LIST_MAPPING = {
  ITEM_LIST_HEADER: 'ดูแลสุขภาพง่ายๆ ได้ทุกวัน!',
  ITEM_LIST_BG_IMAGE:
    '/images/healthAndWellness/goals/supplements/supplements_goal_bg.svg',
  GOAL_REFERRER_VALUE: 'supplements-goal',
  LOG_REFERRER_VALUE: 'supplements-log',
  SEARCH_PLACEHOLDER: 'ค้นหาผลิตภัณฑ์เสริมอาหาร',
};

export const H_W_CHECKBOX_MAPPING = {
  checked: '/images/healthAndWellness/goals/common/checkbox_checked.svg',
  unchecked: '/images/healthAndWellness/goals/common/checkbox_unchecked.svg',
  intermediate: '/images/healthAndWellness/goals/common/checkbox_intermed.svg',
};

export const nutritionCard = [
  {
    name: 'caloriesPerDay',
    label: LABEL_CALORIE,
    totalKeyName: 'totalCalories',
    maxKeyName: 'targetCalories',
  },
  {
    name: 'carbohydratePerDay',
    label: LABEL_CARBS,
    totalKeyName: 'totalCarbs',
    maxKeyName: 'targetCarbs',
  },
  {
    name: 'proteinPerDay',
    label: LABEL_PROTEIN,
    totalKeyName: 'totalProtein',
    maxKeyName: 'targetProtein',
  },
  {
    name: 'fatPerDay',
    label: LABEL_FAT,
    totalKeyName: 'totalFat',
    maxKeyName: 'targetFat',
  },
];

export const FOOD_BAR_NAME = 'food';
export const EXERCISE_BAR_NAME = 'exercise';
export const EXERCISE_BURN_NAME = 'exercise_burn';
export const WATER_BAR_NAME = 'water';
export const TIME_BAR_NAME = 'time';

export const Y_AXIS_MAX_VALUE_LIMIT = 1.1;
export const Y_AXIS_DEFAULT_MAX_LABEL = 3;

export const foodAndExerciseSeries = [
  {
    name: FOOD_BAR_NAME,
    type: GRAPH_TYPE.BAR,
    color: variables.foodDark,
    color2: variables.foodBarLight,
    data: [],
  },
  {
    name: EXERCISE_BAR_NAME,
    type: GRAPH_TYPE.BAR,
    color: variables.exerciseBarDark,
    color2: variables.exerciseLight,
    data: [],
  },
];

export const CALENDAR_LOG_LIST_CARD_MAPPING = {
  [EXERCISE_BAR_NAME]: 'pathExerciseLogList',
  [FOOD_BAR_NAME]: 'pathFoodLogList',
  [WATER_BAR_NAME]: 'pathFoodLogList',
};

export const foodAndWaterSeries = [
  {
    name: FOOD_BAR_NAME,
    type: GRAPH_TYPE.BAR,
    color: variables.foodDark,
    color2: variables.foodBarLight,
    data: [],
  },
  {
    name: WATER_BAR_NAME,
    type: GRAPH_TYPE.BAR,
    color: variables.waterDark,
    color2: variables.waterBarLight,
    data: [],
  },
];

export const exerciseAndTimeSeries = [
  {
    name: EXERCISE_BAR_NAME,
    color: variables.exerciseBarDark,
    color2: variables.exerciseLight,
    data: [],
  },
];

export const GOALS_NUTRITION_DETAILS = {
  SETTER_CARD_FOOTER_FIRST:
    'เราสนับสนุนให้ใช้ค่าที่เราแนะนำ เพื่อผลลัพธ์ที่ดีที่สุดของคุณ',
  SETTER_CARD_FOOTER_SECOND: 'อย่างไรก็ตาม, คุณสามารถปรับค่าได้ถ้าต้องการ',
  SUGGESTION_LIST_FIRST:
    'เราได้คำนวณปริมาณ แคลอรี/โปรตีน/คาร์โบไฮเดรต/ไขมันต่อวันที่เหมาะสมกับคุณ',
  SUGGESTION_LIST_SECOND_LEFT: 'กด',
  SUGGESTION_LIST_SECOND_RIGHT: 'เพื่อ เพิ่ม/ลด เป้าหมาย',
  SUGGESTION_LIST_THIRD: 'กดตัวเลขเพื่อระบุจำนวน',
  GOAL_NUTRTION_MAPPING: {
    targetCalories: {
      heading: 'แคลอรีต่อวัน',
      default: '2000',
      min: '1',
      max: '9999',
      stepper: '100',
      unit: 'แคลอรี',
    },
    targetProtein: {
      heading: 'โปรตีนต่อวัน',
      default: '175',
      min: '1',
      max: '9999',
      stepper: '10',
      unit: 'กรัม',
    },
    targetCarbs: {
      heading: 'คาร์โบไฮเดรตต่อวัน',
      default: '255',
      min: '1',
      max: '9999',
      stepper: '10',
      unit: 'กรัม',
    },
    targetFat: {
      heading: 'ไขมันต่อวัน',
      default: '44',
      min: '1',
      max: '9999',
      stepper: '10',
      unit: 'กรัม',
    },
  },
};

export const NUTRITION_CARD_MAPPING = {
  Calories: {
    max: 9999,
  },
  Carbohydrate: {
    max: 9999,
  },
  Protien: {
    max: 9999,
  },
  Fat: {
    max: 9999,
  },
};

export const FOOD_DETAILS_PICKER = {
  header: 'เลือกมื้ออาหาร',
  backLabel: 'กลับหน้าเมนู',
  pickerTypes: [
    'อาหารเช้า',
    'อาหารกลางวัน',
    'อาหารเย็น',
    'ผลิตภัณฑ์เสริมอาหาร',
  ],
  defaultvalue: 'อาหารกลางวัน',
};

export const FOOD_TYPE_MAPPER = {
  อาหารเช้า: 'breakfast',
  อาหารกลางวัน: 'lunch',
  อาหารเย็น: 'dinner',
  ผลิตภัณฑ์เสริมอาหาร: 'dietarySupplement',
  ['supplements-log']: 'dietarySupplement',
  breakfast: 'breakfast',
  lunch: 'lunch',
  dinner: 'dinner',
};

export const foodTypeReferrers = ['breakfast', 'lunch', 'dinner'];

export const FOOD_CATEGORIES_MAPPING = [
  { name: 'heading', label: LABEL_BUY_FOOD },
  { name: 'breakfast', label: LABEL_BREAKFAST },
  { name: 'lunch', label: LABEL_LUNCH },
  { name: 'dinner', label: LABEL_DINNER },
  { name: 'supplement', label: LABEL_DIET_SUPPLEMENTS },
  { name: 'end', label: '' },
];

export const logListCardMapping = {
  food: {
    icon: '/images/healthAndWellness/foodLog/icons/food_log_menu_icon.svg',
    unit: 'แคลอรี',
  },
  water: {
    icon: '/images/healthAndWellness/foodLog/icons/water_log_menu_icon.svg',
    unit: 'มิลลิลิตร',
  },
  exercise: {
    icon: '/images/healthAndWellness/exercise-log/icons/icon-burn-orange.svg',
    unit: 'แคลอรี',
    timeIcon: '/images/healthAndWellness/exercise-log/icons/clock.svg',
    timeUnit: 'นาที',
  },
  // Add more mappings for eg exercise
};

export const exerciseLogListMapping = {
  food: {
    headerIcon:
      '/images/healthAndWellness/foodLog/icons/food_log_header_icon.svg',
    headerText: 'รายการอาหาร',
  },
  water: {
    headerIcon:
      '/images/healthAndWellness/foodLog/icons/water_log_header_icon.svg',
    headerText: 'รายการน้ำดื่ม',
  },
  exercise: {
    headerIcon:
      '/images/healthAndWellness/exercise-log/icons/icon-burn-orange-circle.svg',
    headerText: 'รายการการออกกำลังกาย',
  },
  // Add more mappings for eg exercise
};

export const logListMapping = {
  food: {
    headerIcon:
      '/images/healthAndWellness/foodLog/icons/food_log_header_icon.svg',
    headerText: 'รายการอาหาร',
  },
  water: {
    headerIcon:
      '/images/healthAndWellness/foodLog/icons/water_log_header_icon.svg',
    headerText: 'รายการน้ำดื่ม',
  },
  exercise: {
    headerIcon:
      '/images/healthAndWellness/exercise-log/icons/icon-burn-orange.svg',
    headerText: 'รายการการออกกำลังกาย',
  },
  // Add more mappings for eg exercise
};

export const waterImagesMapping = {
  water: {
    image: '/images/healthAndWellness/foodLog/icons/water-image.svg',
    title: 'น้ำดื่ม',
  },
  softdrink: {
    image: '/images/healthAndWellness/foodLog/icons/soft-drink-image.svg',
    title: 'น้ำหวาน และน้ำอัดลม',
  },
  tea: {
    image: '/images/healthAndWellness/foodLog/icons/tea-image.svg',
    title: 'ชา กาแฟ',
  },
  // Add more mappings for eg exercise
};

export const HNW_EXERCISE_LOG = 'exercise';
export const CUSTOMER_SESSION_KEY = 'customer';

export const HNW_GOAL_SETTING = {
  CUSTOMER_SESSION_KEY: 'customer',
  ACTIVITY: {
    HEADER: 'กิจกรรม',

    EXERCISE_TIME_HOUR_OPTIONS: ['1', '2', '3', '4'],
    EXERCISE_TIME_HOUR_UNIT_VALUE: 'ชม.',

    BURN_CALORIE_HEADER: 'การเผาผลาญแคลอรี',
    BURN_CALORIE_DEFAULT_VALUE: 0,
    BURN_CALORIE_STEPPER_VALUE: 100,
    BURN_CALORIE_MIN_VALUE: 1,
    BURN_CALORIE_MAX_VALUE: 9999,
    BURN_CALORIE_UNIT_RECOMMENDED: 'แคลอรี (แนะนำ)',
    BURN_CALORIE_UNIT: 'แคลอรี',

    EXERCISE_TIME_HEADER: 'ระยะเวลาออกกำลังกาย',
    EXERCISE_TIME_DEFAULT_VALUE: 30,
    EXERCISE_TIME_STEPPER_VALUE: 10,
    EXERCISE_TIME_MIN_VALUE: 1,
    EXERCISE_TIME_MAX_VALUE: 300,
    EXERCISE_TIME_UNIT_RECOMMENDED: 'นาที (แนะนำ)',
    EXERCISE_TIME_UNIT: 'นาที',

    EXERCISE_QUICK_ACCESS_MULTIPLIER: 60,

    ACTIVITY_NOTE_1: 'เราได้คำนวณการเผาผลาญ และระยะเวลาออกกำลังกาย',
    ACTIVITY_NOTE_2: 'ต่อวันที่เหมาะสมที่สุดกับคุณแล้ว',
    ACTIVITY_NOTE_2_PREFIX: 'กด',
    ACTIVITY_NOTE_2_SUFFIX: 'เพื่อ เพิ่ม/ลด เป้าหมาย, กดตัวเลขเพื่อระบุจำนวน',

    EXERCISE_HEADER: 'ออกกำลังกาย',
    EXERCISE_NOTE: 'เลือกประเภทการออกกำลังกาย (ทางเลือก)',

    ADD_NEW_BTN_TEXT: 'เพิ่มเป้าหมาย',

    EXERCISE_MENU_CANCEL_TH: 'ยกเลิก',
    EXERCISE_MENU_ADD_TH: 'เพิ่ม',
    SELECTED_IN_TH: 'เลือกแล้ว',
    MINUTE_UNIT: 'นาที',

    COLLECTION_ID: 'id',
    COLLECTION_VALUE: 'suggestionTime',
    COLLECTION_IMAGE: 'image',

    DEFAULT_COLLECTION_ID: 'supplementId',
    DEFAULT_COLLECTION_VALUE: 'quantity',
    DEFAULT_COLLECTION_IMAGE: 'imageUrl',

    CHECKBOX_LABLE: 'เลือกทั้งหมด',
    DELETE_BTN_LABLE: 'ลบ',
    SUBMIT_BTN_LABLE: 'ตั้งเป้าหมาย',
    MODAL_HEADER_LABLE: 'สร้างเมนูสำเร็จ',
  },
};

export const EXERCISE_GOAL_CONTAINER = {
  header: 'เป้าหมายของคุณ',
  content: 'เพื่อสุขภาพที่ดีในแต่ละวันมาสร้าง ',
  info: 'เป้าหมายการออกกำลังกายของคุณกัน!',
  btn: 'เริ่มตั้งเป้าหมาย!',
};

export const EXERCISE_CATEGORY_LIST = {
  header: 'รายการกิจกรรม',
};

export const EXERCISE_RECOMMENDED_LIST = {
  header: 'การออกกำลังกายที่แนะนำ',
  duration: 'ระยะเวลา',
  mins: 'นาที',
};

export const EXERCISE_GOAL_LIST = {
  header: 'เป้าหมายของคุณ',
  mins: 'นาที',
  calories: 'แคลอรี',
};
export const EXERCISE_DAILY_ACTIVITY = {
  header: 'กิจกรรมวันนี้',
  times: 'ครั้ง',
  calories: 'แคลอรี',
};

export const FOOD_DETAILS_QTY_TITLE = 'จำนวน';
export const FOOD_DETAILS_AMT_TITLE = 'ปริมาณ';
export const FOOD_DETAILS_AMT_UNIT = 'กรัม';
export const FOOD_DETAILS_ENERGY_TITLE = 'พลังงาน';
export const FOOD_DETAILS_ENERGY_UNIT = 'แคลอรี่';
export const FOOD_DETAILS_CONSUM_QTY_TITLE = 'ปริมาณการบริโภค';
export const FOOD_DELETE_MODAL_HEADER = 'คุณต้องการลบใช่หรือไม่';
export const FOOD_DELETE_MODAL_CANCEL = 'เก็บไว้ก่อน';
export const FOOD_DELETE_MODAL_DELETE = 'ลบ';

export const OTHERS = 'others';
export const ALL = 'all';

export const LABEL_EN_SUGGEST_LOG_TAB = 'suggest';
export const LABEL_EN_MANUAL_LOG_TAB = 'manual';
export const LABEL_TH_SUGGEST_LOG_TAB = 'แอมเวย์แนะนำ';
export const LABEL_TH_MANUAL_LOG_TAB = 'กำหนดเอง';
export const AMWAY_SUGGEST_TYPE = 'amway-suggest';
export const MAP_AMWAY_SUGGEST_AND_SUGGEST = {
  [AMWAY_SUGGEST_TYPE]: LABEL_EN_SUGGEST_LOG_TAB,
  [LABEL_EN_MANUAL_LOG_TAB]: LABEL_EN_MANUAL_LOG_TAB,
};
export const MAP_SUGGEST_AND_AMWAY_SUGGEST = {
  [LABEL_EN_SUGGEST_LOG_TAB]: AMWAY_SUGGEST_TYPE,
  [LABEL_EN_MANUAL_LOG_TAB]: LABEL_EN_MANUAL_LOG_TAB,
};

export const ICON_SALAD = 'icon-salad.svg';
export const ICON_SALAD_MAX = 'icon-salad-max.svg';
export const ICON_BURN_EXERCISE = 'icon-burn-exercise.svg';

export const EXERCISE_LOG_DETAIL = {
  SUGGEST_OR_MANUAL_LOG_TAB: {
    [LABEL_EN_SUGGEST_LOG_TAB]: {
      name: LABEL_EN_SUGGEST_LOG_TAB,
      label: LABEL_TH_SUGGEST_LOG_TAB,
    },
    [LABEL_EN_MANUAL_LOG_TAB]: {
      name: LABEL_EN_MANUAL_LOG_TAB,
      label: LABEL_TH_MANUAL_LOG_TAB,
    },
  },
  LOG_DETAIL: {
    TITLE: 'การบันทึกกิจกรรม',
    CREATE_BTN: 'บันทึกกิจกรรม',
    DELETE_BTN: 'ลบข้อมูล',
    EDIT_BTN: 'บันทึก',
    EXERCISE_HEADER: 'รายละเอียดการออกกำลังกาย',
    EXERCISE_NOTE_1: 'กรอกข้อมูลเพื่อบันทึกการออกกำลังกายของคุณ',

    START_TIME: 'เวลาเริ่มต้น',
    END_TIME: 'เวลาสิ้นสุด',

    EXERCISE_CALORIE: 'แคลอรี',
    NO_OF_TIMES: 'จำนวนครั้ง',
    AVG_BPM: 'อัตราการเต้นหัวใจเฉลี่ย (bpm)',
    MAX_BPM: 'อัตราการเต้นหัวใจสูงสุด (bpm)',

    BURN_CALORIE_HEADER: 'การเผาผลาญแคลอรี',
    BURN_CALORIE_DEFAULT_VALUE: 1,
    BURN_CALORIE_STEPPER_VALUE: 100,
    BURN_CALORIE_MIN_VALUE: 1,
    BURN_CALORIE_MAX_VALUE: 9999,
    BURN_CALORIE_UNIT_RECOMMENDED: 'แคลอรี (แนะนำ)',
    BURN_CALORIE_UNIT: 'แคลอรี',

    EXERCISE_TIME_HEADER: 'ระยะเวลาออกกำลังกาย',
    EXERCISE_TIME_DEFAULT_VALUE: 1,
    EXERCISE_TIME_STEPPER_VALUE: 1,
    EXERCISE_TIME_MIN_VALUE: 1,
    EXERCISE_TIME_MAX_VALUE: 300,
    EXERCISE_TIME_UNIT_RECOMMENDED: 'นาที (แนะนำ)',
    EXERCISE_TIME_UNIT: 'นาที',

    EXERCISE_DEFAULT_TIME: '00:00',
  },
  TIME_LOG: {
    HEADING: 'ช่วงเวลาที่บันทึก',
    ADD_NEW: 'เพิ่มช่วงเวลาใหม่',
  },
  // API INTEGRATION
  AMWAY_SUGGEST_TYPE: AMWAY_SUGGEST_TYPE,
  MANUAL_TYPE: LABEL_EN_MANUAL_LOG_TAB,

  ACTION_TYPE_CREATE: 'CREATE',
  ACTION_TYPE_UPDATE: 'UPDATE',
  ACTION_TYPE_DELETE: 'DELETE',

  ACTION_MODAL_DELETE_CONFIRMATION: 'คุณต้องการลบข้อมูล ใช่หรือไม่',
  ACTION_MODAL_CONFIRM_DELETE: 'ไม่',
  ACTION_MODAL_CONFIRM_CANCEL: 'ลบข้อมูล',
  ACTION_MODAL_CONFIRM_CANCEL_IOS: 'ยกเลิก',
  ACTION_MODAL_CONFIRM_DELETE_IOS: 'ลบข้อมูล',

  TEMPLATE_INPUT_TIME: 'Time',
  TEMPLATE_INPUT_NUMPAD: 'Number',
  TEMPLATE_INPUT_NUMPAD_TYPE: 'Numpad',
  TEMPLATE_INPUT_CALORIES: 'Calories',
};

export const EXERCISE_TIME_KEY = 'excerciseTime';
export const CALORIES_BURN_KEY = 'caloriesBurn';
export const START_TIME_KEY = 'startTime';
export const END_TIME_KEY = 'endTime';
export const CALORIE_KEY = 'calorie';
export const NO_OF_TIMES_KEY = 'noOfTimes';
export const AVG_HEART_RATE_KEY = 'avgHeartRate';
export const MAX_HEART_RATE_KEY = 'maxHeartRate';
export const DIST_COVERED_KEY = 'distCovered';
export const AVG_SPEED_KEY = 'avgSpeed';
export const SLOPE_KEY = 'slope';
export const CADENCE_KEY = 'cadence';
export const LATENCY_LEVEL_KEY = 'latencyLevel';

export const GOAL_DELETE_MODAL_HEADER = 'คุณต้องการลบใช่หรือไม่';
export const GOAL_DELETE_MODAL_CANCEL = 'เก็บไว้ก่อน';
export const GOAL_DELETE_MODAL_DELETE = 'ลบ';

export const HNW_EXERCISE_LOG_DETAIL_INITIAL_STATE = {
  id: '',
  type: EXERCISE_LOG_DETAIL.SUGGEST_OR_MANUAL_LOG_TAB[LABEL_EN_SUGGEST_LOG_TAB]
    .name,
  [EXERCISE_TIME_KEY]: HNW_GOAL_SETTING.ACTIVITY.EXERCISE_TIME_DEFAULT_VALUE,
  [CALORIES_BURN_KEY]: HNW_GOAL_SETTING.ACTIVITY.BURN_CALORIE_DEFAULT_VALUE,
  [START_TIME_KEY]: '',
  [END_TIME_KEY]: '',
  [CALORIE_KEY]: '-',
  [NO_OF_TIMES_KEY]: '-',
  [AVG_HEART_RATE_KEY]: '-',
  [MAX_HEART_RATE_KEY]: '-',
  [DIST_COVERED_KEY]: '-',
  [AVG_SPEED_KEY]: '-',
  [SLOPE_KEY]: '-',
  [CADENCE_KEY]: '-',
  [LATENCY_LEVEL_KEY]: '-',
};

export const HNW_EXERCISE_LOG_ERROR_INITIAL_STATE = {
  [EXERCISE_TIME_KEY]: '',
  [CALORIES_BURN_KEY]: '',

  [START_TIME_KEY]: '',
  [END_TIME_KEY]: '',

  [CALORIE_KEY]: '-',
  [NO_OF_TIMES_KEY]: '-',
  [AVG_HEART_RATE_KEY]: '-',
  [MAX_HEART_RATE_KEY]: '-',
  [DIST_COVERED_KEY]: '-',
  [AVG_SPEED_KEY]: '-',
  [SLOPE_KEY]: '-',
  [CADENCE_KEY]: '-',
  [LATENCY_LEVEL_KEY]: '-',
};

export const HNW_LINE_INPUT = {
  TYPES: {
    TEXT: 'text',
    NUMBER: 'number',
    TIME: 'time',
    DATE: 'date',
    WEEK: 'week',
    CUSTOM: 'custom',
  },
  ON_CHANGE: 'ON_CHANGE',
  ON_DELETE: 'ON_DELETE',
};
export const SUCCESS_TYPE = 'success';
export const ERROR_TYPE = 'error';

export const RELEASE_DATE = new Date(2024, 6, 5); // To-do change the date to production release date when deploying to production
console.log('env:', process.env.ENV_VAR);

export const TERMS_AND_POLICY_TEXT = {
  title: 'คุณแน่ใจใช่ไหม?',
  subtitle: ['ข้อมูลสุขภาพทั้งหมดของคุณจะถูกลบ', 'และไม่สามารถกู้คืนได้'],
};

export const TERMS_AND_CONDITIONS = {
  title: 'ข้อตกลง และเงื่อนไข',
  cancelButtonText: 'ไม่ยอมรับเงื่อนไข',
  saveButtonText: 'ยอมรับเงื่อนไข',
  scrollButtonText: 'เลื่อนไปด้านล่าง',
  cardListText: `ฉันเข้าใจดีว่าข้อมูลด้านสุขภาพ ของฉันทั้งหมด
  จะถูกเก็บไว้ตั้งแต่ วันที่ส่งข้อมูลผ่านแอป จนถึงวันที่ผู้ใช้
  เพิกถอนความยินยอม ในการให้ข้อมูลผ่านแอป
  หรือลบบัญชีบนแอปของตน ดังนั้น หากคุณไม่ต้องการ
  ให้ข้อมูลข้างต้น กรุณางดการใช้แอป เนื่องจากข้อมูลดังกล่าว
  จำเป็นต่อการนำไปใช้เพื่อนำเสนอและวิเคราะห์
  โซลูชันเพื่อการควบคุม หรือลดน้ำหนัก และการดูแลสุขภาพ
  ที่มีอยู่ในแอปนี้`,
  contentText: `ฉันเข้าใจและยินยอมให้รวบรวมข้อมูลด้านสุขภาพ
  ของฉันเพื่อการให้บริการโซลูชั่น เพื่อการควบคุม หรือลดน้ำหนัก
  และการดูแลสุขภาพ ผ่าน [LINE AMWAY THAILAND OFFICIAL ACCOUNT]
  (“แอป”) ที่ให้ไว้บนแพลตฟอร์ม LINE AMWAY THAILAND OFFICIAL
  ACCOUNT ข้อมูลด้านสุขภาพ ที่รวบรวมผ่านแอป มีดังต่อไปนี้:`,
};

export const FOOD_DETAILS_DESCRIPTION_HEAD_TEXT = 'ข้อมูลการบันทึก';

export const NOTIFICATION_WRONG_USER_1 = 'ขออภัย ไลน์ไอดีของคุณ';
export const NOTIFICATION_WRONG_USER_2 = 'ไม่ตรงกับในระบบ';
export const NOTIFICATION_WRONG_USER_3 = 'กรุณาเลือกไลน์ไอดี ใหม่อีกครั้ง';

export const WORKOUT_CAT_TYPE = 'สร้างกล้าม';
export const CARDIO_CAT_TYPE = 'คาร์ดิโอ';
export const CYCLING_CAT_TYPE = 'ปั่นจักรยาน';
export const WALKING_AND_RUNNING_CAT_TYPE = 'เดินและวิ่ง';

export const EXERCISE_KILOMETER_UNIT = 'กิโลเมตร';
export const EXERCISE_DURATION_IN_MIN_UNIT = 'นาที';
export const EXERCISE_TIME_UNIT = 'ครั้ง';

export const BUTTON_TAGNAME = 'BUTTON'

export const FOOTER_MENU_MAPPING = [
  {
    id: 'home',
    label: 'หน้าหลัก',
    navBarUrl: '/images/common/footer/bottom-nav-home.svg',
    iconUrl: '/images/common/footer/icons/home.svg',
    routeUrl: commonConstant.pathHealthnWellness,
  },
  {
    id: 'calories',
    label: 'แคลอรี',
    navBarUrl: '/images/common/footer/bottom-nav-calorie.svg',
    iconUrl: '/images/common/footer/icons/calorie.svg',
    routeUrl: commonConstant.pathFoodLogHome,
  },
  {
    id: 'challenge',
    label: 'การแข่งขัน',
    navBarUrl: '/images/common/footer/bottom-nav-challenge.svg',
    iconUrl: '/images/common/footer/icons/challenge.svg',
    isCenter: true,
    routeUrl: commonConstant.pathChallengeList,
  },
  {
    id: 'exercise',
    label: 'กิจกรรม',
    navBarUrl: '/images/common/footer/bottom-nav-exercise.svg',
    iconUrl: '/images/common/footer/icons/activity.svg',
    routeUrl: commonConstant.pathHnwExerciseLogHome,
  },
  {
    id: 'profile',
    label: 'โปรไฟล์',
    navBarUrl: '/images/common/footer/bottom-nav-profile.svg',
    iconUrl: '/images/common/footer/icons/profile.svg',
    routeUrl: commonConstant.pathProfile,
  },
]

export const FOOTER_ROUTE_MAPPING = {
  home: 'home',
  calories: 'calories',
  challenge: 'challenge',
  exercise: 'exercise',
  profile: 'profile'
}

export const CAMERA_CC_VAR = {
  NO_CAMERA_ACCESSIBLE_ERR: 'No camera device accessible. Please connect your camera or try a different browser.',
  PERMISSION_DENIED_ERR: 'Permission denied. Please refresh and give camera permission.',
  SWITCH_CAMERA_ERR: 'It is not possible to switch camera to different one because there is only one video device accessible.',
  CANVAS_NOT_SUPPORTED_ERR: 'Canvas is not supported.',
}

export const ANDROID_UPLOAD_PICKER = {
  CHOOSE_ACTION: "เลือกการทำงาน",
  ANDROID_CAMERA_ACTIONS: [
    {
      imageIcon: "/images/challenge/myupload/camera.svg",
      actionThai: "กล้องถ่ายรูป",
      type: "takePhoto"
    },
    {
      imageIcon: "/images/challenge/myupload/photos.svg",
      actionThai: "เครื่องมือเลือกสื่อ",
      type: "uploadPhoto"
    }
  ]
}
export const homePageSlideUpGap = '120px';
export const homePageTabBtnSize = '16px';

export const REGEX_YOUTUBE_ID = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
export const REGEX_YOUTUBE_DURATION = /PT(\d+H)?(\d+M)?(\d+S)?/;
export const YOUTUBE_WATCH_URI = 'https://www.youtube.com/iframe_api';

export const ANDROID_CAMERA_PAGE_CANCEL_BTN = "ยกเลิก"

export const AMWAY_CLICK_DEEPLINK = 'amway://app/sopmain';