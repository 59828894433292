import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AreaGraph from '../../shared/graph/area';
import BarGraph from '../../shared/graph/bar';
import { Dropdown, HnWNumpadModal, Numpad } from '../../../common';
import { fetchBmiData, updateBmiData, fetchMonthlyBmiData } from '../../../actions';
import commonConstant, { H_W_VALIDATION, HEALTH_AND_WELLNESS_TYPE, INPUT_PLACEHOLDER } from '../../../common/commonConstant';
import { thaiDate } from '../../../utill.func';
import './HeightAndWeight.scss';
import LoadingOverlay from 'react-loading-overlay';
import { getMonthFirstDayAndLastDay } from '../../shared/graph/graph-util';

const WeightPage = ({ type, otherType }) => {
  const currentDate = new Date();
  let lastUpdatedData = { updatedDate: currentDate, [type]: null };
  const currentMonthIndex = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const [selectedMonthIndex, setSelectedMonthIndex] = useState(currentMonthIndex);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const dispatch = useDispatch();
  const [openNumpad, setOpenNumpad] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [healthInfo, setHealthInfo] = useState('');
  const { monthlyBmi, lastMonthBmi, ...bmi } = useSelector((state) => state.bmi);
  const monthlyBmiData = monthlyBmi.filter((item) => item[type]);
  const lastMonthBmiData = lastMonthBmi.filter((item) => item[type]);

  const isPreviousMonthData = currentMonthIndex !== selectedMonthIndex;
  const [selectedGraphValue, setSelectedGraphValue] = useState({ x: currentDate, y: bmi[type] });
  const [loading, setLoading] = useState(false);
  const [defaultInput, setDefaultInput] = useState('');

  const heightAndWeightMapping = {
    height: {
      iconLabel: 'ส่วนสูง',
      unit: 'เซนติเมตร',
      updateHeadingLabel: 'อัปเดตส่วนสูง',
      updateUnitLabel: 'ส่วนสูง (เซนติเมตร)',
      graph: BarGraph,
    },
    weight: {
      iconLabel: 'น้ำหนัก',
      unit: 'กิโลกรัม',
      updateHeadingLabel: 'อัปเดตน้ำหนัก',
      updateUnitLabel: 'น้ำหนัก (กิโลกรัม)',
      graph: AreaGraph,
    },
    waist: {
      iconLabel: 'เส้นรอบเอว',
      unit: 'นิ้ว',
      updateHeadingLabel: 'อัปเดตเส้นรอบเอว',
      updateUnitLabel: 'เส้นรอบเอว (นิ้ว)',
      graph: AreaGraph,
    },
    muscle: {
      iconLabel: 'มวลกล้ามเนื้อ',
      unit: 'กิโลกรัม',
      updateHeadingLabel: 'อัปเดตมวลกล้ามเนื้อ',
      updateUnitLabel: 'มวลกล้ามเนื้อ (กิโลกรัม)',
      graph: BarGraph,
    },
    fat: {
      iconLabel: 'ไขมัน',
      unit: 'กก.',
      updateHeadingLabel: 'อัปเดตค่าไขมัน',
      updateUnitLabel: 'ไขมัน (กิโลกรัม)',
      graph: AreaGraph,
    },
  };

  // handling for January
  const previousMonthIndex = currentMonthIndex < 1 ? 11 : currentMonthIndex - 1;
  // handling for January and Februray 
  const secondPreviousMonthIndex = currentMonthIndex < 2 ? 10 + currentMonthIndex : currentMonthIndex - 2;

  const dropDownData = [secondPreviousMonthIndex, previousMonthIndex, currentMonthIndex]
    .map(monthIndex => ({ displayText: commonConstant.MONTHS.th[monthIndex], value: monthIndex }));
  const dropdownValue = { displayText: commonConstant.MONTHS.th[selectedMonthIndex], value: selectedMonthIndex }

  useEffect(() => {
    lastUpdatedData = isPreviousMonthData ?
      // for previous month
      (monthlyBmiData.length ? monthlyBmiData[monthlyBmiData.length - 1] :
        { updatedDate: getMonthFirstDayAndLastDay(selectedMonthIndex, selectedYear).lastDayOfMonth, [type]: null }) :
      // for current month
      {
        updatedDate: currentDate,
        [type]: (bmi[`${type}UpdatedDate`] === '' || new Date(bmi[`${type}UpdatedDate`]).getMonth() === currentMonthIndex) ? bmi[type] : null,
      };

    setSelectedGraphValue({ x: lastUpdatedData[type] ? lastUpdatedData.updatedDate : bmi[`${type}UpdatedDate`], y: lastUpdatedData[type] ?? bmi[type] });
  }, [monthlyBmi]);

  useEffect(() => {
    setLoading(true);
    if (!bmi?.weight) {
      dispatch(fetchBmiData());
    }
    dispatch(fetchMonthlyBmiData(currentMonthIndex, currentYear))
      .then(() => {
        const relatedYear = previousMonthIndex > currentMonthIndex ? currentYear - 1 : currentYear;
        dispatch(fetchMonthlyBmiData(previousMonthIndex, relatedYear, true))
          .finally(() => setLoading(false));
      }).then(() => {
        setLoading(false);
        setIsMounted(true);
      })
      .finally(() => setLoading(false));
  }, [type]);

  useEffect(() => {
    !bmi[`${type}UpdatedDate`] && setHealthInfo(bmi[type]);
    setSelectedGraphValue({ x: bmi[`${type}UpdatedDate`], y: bmi[type] });
  }, [bmi[type]]);

  const handleSubmitValue = () => {
    setLoading(true);
    const finalValue = Number.parseFloat(healthInfo);
    setSelectedGraphValue({ x: currentDate, y: healthInfo });
    const payload = {
      [type]: finalValue
    };
    if (otherType) {
      payload[otherType] = bmi[otherType];
    }
    dispatch(updateBmiData(payload)).then(() => {
      dispatch(fetchMonthlyBmiData(currentMonthIndex, currentYear));
    }).finally(() => setLoading(false));
  };

  const handleChangeInput = (value) => {
    const currentvalue = defaultInput ?? '';
    const regex = (H_W_VALIDATION[type].pattern);
    const newValue = currentvalue + value;
    if (regex.test(newValue)) {
      setHealthInfo(newValue);
      setDefaultInput(newValue);
    }
  };

  const handleDropdownSelect = ({ value }) => {
    setLoading(true);
    setSelectedMonthIndex(+value);
    const selectedMonthYear = value > currentMonthIndex ? currentYear - 1 : currentYear;
    setSelectedYear(selectedMonthYear);
    // ternary operator to handle previous year value
    dispatch(fetchMonthlyBmiData(value, selectedMonthYear))
      .then(() => {
        const previousMonthIndex = value < 1 ? 11 : value - 1;
        const previousMonthYear = previousMonthIndex > value ? currentYear - 1 : currentYear;
        dispatch(fetchMonthlyBmiData(previousMonthIndex, previousMonthYear, true))
          .finally(() => setLoading(false));
      })
      .finally(() => setLoading(false));
  };

  const handleNumpadClick = () => {
    toggleNumpad(true);
    setHealthInfo('');
    setDefaultInput('');
  };

  const graphData = monthlyBmiData.map((dateData) => (
    { x: new Date(dateData.year, dateData.month - 1, dateData.day), y: +dateData[type] }
  ));

  const lastMonthGraphData = lastMonthBmiData?.length && lastMonthBmiData.map((dateData) => (
    { x: new Date(dateData.year, dateData.month, dateData.day), y: +dateData[type] }),
  );

  const Graph = heightAndWeightMapping[type].graph;
  const lastMonthsLastData = lastMonthGraphData[lastMonthGraphData.length - 1];

  const GraphComp = useMemo(() => isMounted && <Graph data={graphData} secondaryData={lastMonthGraphData}
    onMouseMove={setSelectedGraphValue} latestData={lastMonthsLastData?.y ?? bmi[type]} isPreviousMonth={isPreviousMonthData}
    month={selectedMonthIndex} year={selectedYear} />, [isMounted, lastMonthGraphData?.length, graphData.length, monthlyBmi, bmi]);

  // toggle numpad
  const toggleNumpad = (val) => {
    setOpenNumpad(val);
  };
  // On delete at modal updating state of component
  const handleDelete = () => {
    const value = defaultInput && defaultInput.toString();
    const updatedValue = value.slice(0, -1);
    if (value) {
      setDefaultInput(updatedValue);
      setHealthInfo(updatedValue);
    }
  };

  // On OK click at modal updating state of component and calling api for update
  const handleOKClick = () => {
    toggleNumpad(false);
    if (healthInfo && (bmi[`${type}UpdatedDate`] || healthInfo != bmi[type])) {
      handleSubmitValue();
    } else {
      setHealthInfo(bmi[type]);
    }
  };

  // Renderinng Numpad Modal
  const renderNumpad = () => (
    <Numpad handleInputChange={handleChangeInput}
      handleDelete={handleDelete}
      handleOKClick={handleOKClick}
    />
  );

  const handleDateChange = (value, valueDate) => {
    if (value === '' && valueDate === '' && !isPreviousMonthData) {
      return thaiDate(currentDate, 'dd MMM yyyy');
    }
    else if (value && valueDate) {
      return thaiDate(selectedGraphValue?.x || valueDate, 'dd MMM yyyy');
    }
    else {
      return lastUpdatedData.updatedDate && thaiDate(selectedGraphValue?.x || lastUpdatedData.updatedDate, 'dd MMM yyyy');
    }
  };

  return <div className="hnw-weight">
    <div className={`hnw-weight-details-wrapper ${openNumpad && type !== HEALTH_AND_WELLNESS_TYPE.HEIGHT ? 'set-transition mb-100' : 'set-transition'}`}>
      <div className="hnw-weight-chart">
        <div className="hnw-weight-chart-header">
          <div className="hnw-weight-chart-header-left">
            <img src={`/images/healthAndWellness/heightAndWeight/${type}Icon.svg`} />
            <div className="hnw-weight-chart-header-left-title">
              {heightAndWeightMapping[type].iconLabel}</div>
          </div>
          {
            type !== HEALTH_AND_WELLNESS_TYPE.HEIGHT &&
            <div className="hnw-weight-chart-right">
              <Dropdown data={dropDownData} onDropdownSelect={handleDropdownSelect} defaultValue={dropdownValue} />
            </div>
          }
        </div>
        < div className="hnw-weight-chart-value">
          <div className="hnw-weight-chart-value-1 d-flex">
            {(selectedGraphValue?.y ?? lastUpdatedData[type]) || '-'}
            <span>{heightAndWeightMapping[type].unit}</span>
          </div>
          <div className="hnw-weight-chart-value-2">{handleDateChange(bmi[type], bmi[`${type}UpdatedDate`])}</div>
        </div>
        {type !== HEALTH_AND_WELLNESS_TYPE.HEIGHT && GraphComp}
      </div>
      <div className="hnw-weight-accordian-title-heading">{heightAndWeightMapping[type].updateHeadingLabel}</div>
      <div className="hnw-weight-accordian-container">
        <div className="hnw-weight-accordian-single">
          <div className="hnw-weight-accordian-title">วันที่</div>
          <div className="hnw-weight-accordian-right-section">{thaiDate(currentDate, 'dd MMM yyyy')}</div>
        </div>
        <div className="hnw-weight-accordian-single" onClick={handleNumpadClick}>
          <div className="hnw-weight-accordian-title">{heightAndWeightMapping[type].updateUnitLabel}</div>
          <div className="hnw-weight-accordian-right-section">
            <div className="green font-weight800">
              {openNumpad ? defaultInput : (healthInfo) || INPUT_PLACEHOLDER}
            </div>
          </div>
        </div>
      </div>
      <div className="health-container-modal">
        <HnWNumpadModal onClose={() => toggleNumpad(false)} openNumpad={openNumpad} onBackgroundClick={handleOKClick}>
          {renderNumpad()}
        </HnWNumpadModal>
      </div>
    </div>
    <LoadingOverlay active={loading} spinner text="Loading" styles={{ wrapper: { width: '100%', minHeight: '100%' } }} />
  </div>;
};
export default WeightPage;
