import React, { useState, useEffect, useRef } from 'react';
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import './AddMenu.scss';
import HnWNumpadModal from '../../../../../common/hnWNumpadModal/HnWNumpadModal';
import Numpad from '../../../../../common/hnWNumpadModal/Numpad';
import {
  ADD_MENU_FORM,
  BUTTON_TAGNAME,
  GENDER,
  H_W_FOOD_LOG,
  NUTRITION_ITEMS,
  routePathKey,
} from '../../../../../common/commonConstant';
import { useDispatch } from 'react-redux';
import {
  addFoodMenuDetails,
  fetchBmiData,
  getTargetCalories,
  uploadFoodImage,
} from '../../../../../actions';
import { checkIsIOS, routeTo } from '../../../../../utill.func';
import { useLocation } from 'react-router-dom';
import { AddMenuModel } from '../../../../../model/AddMenu.model';
import { useSelector } from 'react-redux';
import {
  getCustomerAge,
  getCustomerGender,
} from '../../../../../services/healthWellness/healthWellness.service';
import { getDefaultValues } from '../../../../../services/healthWellness/hnwGoals.service';
import config from '../../../../../config';
import { CalendarLoader } from '../../../../../common';
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from '../../../../../services/promotion/utils.service';
import { round } from '../../../../../services';

const ADD_MENU_FORM_DATA = 'addMenuFormData';
const ADD_MENU_FORM_ERROR = 'addMenuFormError';
const DEFAULT_FORM_STATE = {
  image: '',
  productCategory: 'others',
  name: '',
  weight: '',
  quantity: '',
  unit: '',
  calorie: '',
  carbs: null,
  protein: null,
  fat: null,
};
const DEFAULT_ERROR_STATE = {
  image: '',
  productCategory: '',
  name: '',
  weight: '',
  quantity: '',
  unit: '',
  calorie: '',
  carbs: '',
  protein: '',
  fat: '',
};
const AddMenu = ({ routeTo }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const referrerPage = params.get('referrer');
  const imagePath = params.get('imagePath');
  const [isDirty, setIsDirty] = useState({ weight: false, quantity: false, calorie: false, carbs: false, protein: false, fat: false });
  const [loading, setLoading] = useState(false);
  const [openNumpad, setOpenNumpad] = useState(false);
  const [currentInput, setCurrentInput] = useState('');
  const [currentValue, setCurrentValue] = useState('');
  const [fileList, setFileList] = useState([]);
  const [isEnabled, setIsEnabled] = useState(false);
  const formRef = useRef(null);
  const uploadRef = useRef(null);
  const [foodGoal, setFoodGoal] = useState(null);
  const [showPicker, setShowPicker] = useState(false);
  const [imagePathState, setImagePathState] = useState('');

  const buttonClass = `add-menu-btn ${!isEnabled ? 'disabled-btn' : ''}`;

  const bmi = useSelector((state) => state.bmi);
  const customerGender = Object.keys(GENDER).find(
    (item) => GENDER[item] === getCustomerGender(),
  );
  const customerAge = getCustomerAge();
  const defaultValues = getDefaultValues(bmi, customerAge, customerGender);

  const [formData, setFormData] = useState(DEFAULT_FORM_STATE);

  const [errors, setErrors] = useState(DEFAULT_ERROR_STATE);

  const [focusedField, setFocusedField] = useState(null);

  //for checking the mandatory fields
  useEffect(() => {
    const mandatoryFields = ['name', 'weight', 'unit', 'quantity', 'calorie'];
    const enabled = mandatoryFields.every(
      (field) => formData[field] !== '' && errors[field] === '',
    );
    setIsEnabled(enabled);
    if (errors.carbs || errors.protein || errors.fat) {
      setIsEnabled(false);
    }
  }, [formData]);

  useEffect(() => {
    const didMount = async () => {
      await getTargetCaloriesData();
    };
    didMount();
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchBmiData()).then(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (imagePath && imagePath?.startsWith('/assessmsnt/uploads/')) {
      setImagePathState(imagePath);
      setFileList([
        {
          url: `${config.S3_URL}${imagePath}`,
          thumbUrl: `${config.S3_URL}${imagePath}`,
          uid: imagePath.split('/').slice(-1)[0],
          name: imagePath.split('/').slice(-1)[0],
          status: 'done',
          response: '{"status": "success"}',
        },
      ]);
      getLocalStorage(ADD_MENU_FORM_DATA)
        .then((data) => setFormData(data ? JSON.parse(data) : DEFAULT_FORM_STATE))
        .catch((error) => setFormData(DEFAULT_FORM_STATE))
        .finally(() => removeLocalStorage(ADD_MENU_FORM_DATA));
      getLocalStorage(ADD_MENU_FORM_ERROR)
        .then((data) => setErrors(data ? JSON.parse(data) : DEFAULT_ERROR_STATE))
        .catch((error) => setErrors(DEFAULT_ERROR_STATE))
        .finally(() => removeLocalStorage(ADD_MENU_FORM_ERROR));
    } else {
      setImagePathState(null);
      setFileList([]);
    }
  }, [imagePath]);

  const getTargetCaloriesData = async () => {
    try {
      setLoading(true);
      dispatch(getTargetCalories())
        .then((res) => {
          setLoading(false);
          setFoodGoal(res);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const file = fileList[0]?.originFileObj;
      let imageId = !!imagePathState ? imagePathState : '';
      if (file && imagePathState === null) {
        const formDataImage = new FormData();
        formDataImage.append('imageFile', file);
        const imageResponse = await dispatch(uploadFoodImage(formDataImage));
        imageId = imageResponse?.data?.data || '';
      }
      const fd = new AddMenuModel({ ...formData, image: imageId });
      setFormData(fd);
      const formDataResponse = await dispatch(addFoodMenuDetails(fd));
      if (formDataResponse) {
        routeTo(
          routePathKey.foodDetail,
          `?id=${formDataResponse?._id}&showSuccess=true&referrer=${referrerPage}`,
        );
      }
    } catch (error) {
      setLoading(false);
      console.error('An error occurred:', error);
    }

    formRef.current.reset();
  };

  // open toggle numpad
  const toggleNumpad = (val, type) => {
    if (type) {
      setCurrentInput(type);
    }
    setOpenNumpad(val);
    setIsDirty({ ...isDirty, [currentInput]: false });
  };

  // state update dynamically
  const updateAddMenuInfo = (key, value, type) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: type === 'text' || value === '' ? value : parseFloat(value),
    }));
  };

  // state update for error andling
  const updateErrorInfo = (key, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  // On input change at text input updating state of component with updated value
  const handleTextChange = (type, event) => {
    const regex = H_W_FOOD_LOG[type].pattern;
    const isValid = regex.test(event.target.value);
    if (!isValid) {
      event.preventDefault();
    }
    updateAddMenuInfo(type, event.target.value, 'text');
    updateErrorInfo(type, isValid ? '' : ADD_MENU_FORM.error);
  };

  // On input change at modal updating state of component with updated value
  const handleInputChange = (value) => {
    const updatedValue = currentValue + value;
    const regex = H_W_FOOD_LOG[currentInput].pattern;
    const isValid = regex.test(updatedValue);
    setCurrentValue(updatedValue);
    setIsDirty({ ...isDirty, [currentInput]: true });
    updateAddMenuInfo(currentInput, updatedValue, 'num');
    updateErrorInfo(currentInput, isValid ? '' : `${ADD_MENU_FORM.error}`);
  };

  // On delete at modal updating state of component
  const handleDelete = () => {
    const updatedValue = currentValue.slice(0, -1);
    const regex = H_W_FOOD_LOG[currentInput].pattern;
    const isValid = regex.test(updatedValue);
    setCurrentValue(updatedValue);
    updateAddMenuInfo(currentInput, updatedValue, 'num');
    updateErrorInfo(currentInput, isValid ? '' : ADD_MENU_FORM.error);
  };

  // On OK click at modal updating state of component and calling api for update
  const handleOKClick = () => {
    setFocusedField(null);
    toggleNumpad(false);
    const fieldValue = currentValue || formData[currentInput];
    const regex = H_W_FOOD_LOG[currentInput].pattern;
    const isValid = regex.test(fieldValue);
    setCurrentValue(fieldValue);
    if (isValid) {
      updateAddMenuInfo(currentInput, fieldValue, 'num');
      setCurrentValue('');
      updateErrorInfo(currentInput, '');
    } else {
      setCurrentValue('');
      updateErrorInfo(currentInput, isValid ? '' : ADD_MENU_FORM.error);
    }
  };

  // Renderinng Numpad Modal
  const renderNumpad = () => {
    return (
      <Numpad
        handleInputChange={handleInputChange}
        handleDelete={handleDelete}
        handleOKClick={handleOKClick}
      />
    );
  };

  // onChange function for uploading the food image
  const onChange = async ({ file, fileList: newFileList }) => {
    updateErrorInfo('image', '');
    const uploadedImage = newFileList.find(
      (item) => item.uid === file.uid,
    )?.originFileObj;
    if (uploadedImage) {
      const img = new Image();
      img.onload = function () {
        setFileList(
          newFileList.filter((item) => item.status === 'done' || 'uploading'),
        );
      };
      img.src = URL.createObjectURL(uploadedImage);
    }
  };

  // handling of the image before uploading
  const handleBeforeUploadImage = (file) => {
    return new Promise((resolve, reject) => {
      let isImage = file.type.match('image') ? true : false;
      if (!isImage) {
        console.log('false');
        return reject(false);
      } else {
        console.log('true');
      }
      return resolve(true);
    });
  };

  const calculateValues = () => {
    const TotalCalories =
      foodGoal?.targetCalories ?? defaultValues.defaultCalories;
    const TotalCarbs = foodGoal?.targetCarbs ?? defaultValues.defaultCarbs;
    const TotalProtein =
      foodGoal?.targetProtein ?? defaultValues.defaultProtein;
    const TotalFat = foodGoal?.targetFat ?? defaultValues.defaultFat;

    const calculatePercentage = (value, totalValue) =>
      value === 0 ? '-%' : ((value / totalValue) * 100).toFixed(2) + '%';

    const carbs =
      TotalCarbs > 0
        ? calculatePercentage(formData?.carbs || 0, TotalCarbs)
        : '-%';
    const protein =
      TotalProtein > 0
        ? calculatePercentage(formData?.protein || 0, TotalProtein)
        : '-%';
    const fat =
      TotalFat > 0 ? calculatePercentage(formData?.fat || 0, TotalFat) : '-%';
    return [round(TotalCalories, 0) || '-', carbs, protein, fat];
  };
  const calculatedValues = calculateValues();

  const handleFocus = (e) => {
    const { name } = e.target;
    setFocusedField(name);
  };

  return (
    <>
      <CalendarLoader showLoading={loading} />
      <div className="add-menu-form-container">
        <div className="container-fluid">
          <form onSubmit={handleSubmit} ref={formRef}>
            <div className="row add-menu-row">
              <div className="col-12">
                <div className="add-menu-form-title">{ADD_MENU_FORM.title}</div>
              </div>
              <div className="col-12">
                <div className="add-menu-form">
                  <div className="android-dropdown">
                    <div className="add-img-section">
                      <ImgCrop
                        beforeCrop={(file) => {
                          if (!file?.type.startsWith('image/')) {
                            return false;
                          }
                          return true;
                        }}
                      >
                        <Upload
                          listType="picture-card"
                          fileList={fileList}
                          onChange={onChange}
                          beforeUpload={handleBeforeUploadImage}
                          name="image"
                          accept={'image/png,image/jpeg'}
                          openFileDialogOnClick={checkIsIOS()}
                          ref={uploadRef}
                        >
                          {fileList.length ? null : (
                            <img
                              className={formData.image ? 'active' : ''}
                              width={'100%'}
                              src="/images/healthAndWellness/foodLog/add_menu_image.svg"
                              preview="false"
                              onClick={() => {
                                if (checkIsIOS()) return;
                                setShowPicker(!showPicker);
                              }}
                            />
                          )}
                        </Upload>
                      </ImgCrop>
                    </div>
                    {showPicker ? (
                      <ul className="android-dropdown-menu">
                        <li
                          className="list-group-item"
                          onClick={() => {
                            // TODO NEED TO REVISIT IN NEW ARCHITECTURE
                            const currentEnv = window.location.hostname;
                            const isPreProd = /preprod/.test(currentEnv);
                            const cameraLiffUrl = isPreProd
                              ? 'https://liff.line.me/1496956108-RG0eP0ko'
                              : 'https://liff.line.me/1498011841-3xO7ovdo';
                            window.location.href = cameraLiffUrl;
                            setShowPicker(false);
                            setLocalStorage(
                              ADD_MENU_FORM_DATA,
                              JSON.stringify(formData),
                            );
                            setLocalStorage(
                              ADD_MENU_FORM_ERROR,
                              JSON.stringify(errors),
                            );
                          }}
                        >
                          <span>Take Photo</span>{' '}
                          <img
                            src="/images/healthAndWellness/foodLog/take-photo.svg"
                            alt="take-photo"
                          />
                        </li>
                        <li
                          className="list-group-item"
                          onClick={() => {
                            const onClickCallback =
                              uploadRef?.current?.upload?.uploader?.onClick;
                            onClickCallback({
                              target: {
                                tagName: BUTTON_TAGNAME,
                                blur: () => { },
                              },
                            });
                            setShowPicker(false);
                          }}
                        >
                          <span>Choose File</span>{' '}
                          <img
                            src="/images/healthAndWellness/foodLog/choose-file.svg"
                            alt="choose-file"
                          />
                        </li>
                      </ul>
                    ) : null}
                    {errors.image && (
                      <p className="add-menu-form-error">
                        {ADD_MENU_FORM.imgError}
                      </p>
                    )}
                  </div>
                  <div className="input-field-section">
                    <label>
                      {ADD_MENU_FORM.foodMenu}
                      <span className="required-field">*</span>
                    </label>
                    <div
                      className={`input-with-unit-section ${errors.name
                        ? 'error-input'
                        : formData?.name && focusedField === 'name'
                          ? 'success-input'
                          : ''
                        }`}
                    >
                      <input
                        type="text"
                        placeholder={ADD_MENU_FORM.foodMenuLabel}
                        name="name"
                        maxLength={50}
                        minLength={1}
                        onChange={(e) => handleTextChange('name', e)}
                        value={formData.name}
                        onFocus={handleFocus}
                        required
                      />
                      <div className="input-fixed-unit"></div>
                    </div>
                  </div>
                  {errors.name && (
                    <p className="add-menu-form-error">{ADD_MENU_FORM.error}</p>
                  )}
                  <div className="input-field-section">
                    <label>
                      {ADD_MENU_FORM.weight}
                      <span className="required-field">*</span>
                    </label>
                    <div
                      className={`input-with-unit-section ${errors.weight
                        ? 'error-input'
                        : formData?.weight && focusedField === 'weight'
                          ? 'success-input'
                          : ''
                        }`}
                    >
                      <input
                        placeholder="0"
                        name="weight"
                        value={openNumpad && currentInput === "weight" && !isDirty.weight ? '' : formData.weight}
                        onChange={(e) =>
                          handleInputChange('weight', e.target.value)
                        }
                        onClick={() => {
                          toggleNumpad(true, 'weight');
                        }}
                        onFocus={handleFocus}
                        inputMode="none"
                        required
                      />
                      <div className="input-fixed-unit">
                        {ADD_MENU_FORM.grams}
                      </div>
                    </div>
                  </div>
                  {errors.weight && (
                    <p className="add-menu-form-error">{ADD_MENU_FORM.error}</p>
                  )}
                  <div className="input-group-section">
                    <div className="input-field-section">
                      <label>
                        {ADD_MENU_FORM.quantity}
                        <span className="required-field">*</span>
                      </label>
                      <div
                        className={`input-with-unit-section ${errors.quantity
                          ? 'error-input'
                          : formData?.quantity && focusedField === 'quantity'
                            ? 'success-input'
                            : ''
                          }`}
                      >
                        <input
                          placeholder="0"
                          name="quantity"
                          value={openNumpad && currentInput === "quantity" && !isDirty.quantity ? '' : formData.quantity}
                          onChange={(e) =>
                            handleInputChange('quantity', e.target.value)
                          }
                          onClick={() => {
                            toggleNumpad(true, 'quantity');
                          }}
                          onFocus={handleFocus}
                          inputMode="none"
                          required
                        />
                        <div className="input-fixed-unit"></div>
                      </div>
                      {errors.quantity && (
                        <p className="add-menu-form-error">
                          {ADD_MENU_FORM.error}
                        </p>
                      )}
                    </div>

                    <div className="input-field-section">
                      <label>
                        {ADD_MENU_FORM.unit}
                        <span className="required-field">*</span>
                      </label>
                      <div
                        className={`input-with-unit-section ${errors.unit
                          ? 'error-input'
                          : formData?.unit && focusedField === 'unit'
                            ? 'success-input'
                            : ''
                          }`}
                      >
                        <input
                          type="text"
                          placeholder={ADD_MENU_FORM.unitLabl}
                          name="unit"
                          value={formData.unit}
                          onChange={(e) => handleTextChange('unit', e)}
                          onFocus={handleFocus}
                          required
                        />
                        <div className="input-fixed-unit"></div>
                      </div>
                      {errors.unit && (
                        <p className="add-menu-form-error">
                          {ADD_MENU_FORM.error}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="input-field-section">
                    <label>
                      {ADD_MENU_FORM.calorie}
                      <span className="required-field">*</span>
                    </label>
                    <div
                      className={`input-with-unit-section ${errors.calorie
                        ? 'error-input'
                        : formData?.calorie && focusedField === 'calorie'
                          ? 'success-input'
                          : ''
                        }`}
                    >
                      <input
                        placeholder="0"
                        name="calorie"
                        value={openNumpad && currentInput === "calorie" && !isDirty.calorie ? '' : formData.calorie}
                        onChange={(e) =>
                          handleInputChange('calorie', parseInt(e.target.value))
                        }
                        onClick={() => {
                          toggleNumpad(true, 'calorie');
                        }}
                        onFocus={handleFocus}
                        inputMode="none"
                        required
                      />
                      <div className="input-fixed-unit">
                        {ADD_MENU_FORM.calorie}
                      </div>
                    </div>
                  </div>
                  {errors.calorie && (
                    <p className="add-menu-form-error">{ADD_MENU_FORM.error}</p>
                  )}
                  <div className="input-field-section">
                    <label>{ADD_MENU_FORM.carbo}</label>
                    <div
                      className={`input-with-unit-section ${errors.carbs
                        ? 'error-input'
                        : formData?.carbs && focusedField === 'carbs'
                          ? 'success-input'
                          : ''
                        }`}
                    >
                      <input
                        placeholder="0"
                        name="carbs"
                        value={openNumpad && currentInput === "carbs" && !isDirty.carbs ? '' : (formData.carbs ?? '')}
                        onChange={(e) =>
                          handleInputChange('carbs', e.target.value)
                        }
                        onClick={() => {
                          toggleNumpad(true, 'carbs');
                        }}
                        onFocus={handleFocus}
                        inputMode="none"
                      />
                      <div className="input-fixed-unit">
                        {ADD_MENU_FORM.grams}
                      </div>
                    </div>
                  </div>
                  {errors.carbs && (
                    <p className="add-menu-form-error">{ADD_MENU_FORM.error}</p>
                  )}
                  <div className="input-field-section">
                    <label>{ADD_MENU_FORM.protein}</label>
                    <div
                      className={`input-with-unit-section ${errors.protein
                        ? 'error-input'
                        : formData?.protein && focusedField === 'protein'
                          ? 'success-input'
                          : ''
                        }`}
                    >
                      <input
                        placeholder="0"
                        name="protein"
                        value={openNumpad && currentInput === "protein" && !isDirty.protein ? '' : (formData.protein ?? '')}
                        onChange={(e) =>
                          handleInputChange('protein', e.target.value)
                        }
                        onClick={() => {
                          toggleNumpad(true, 'protein');
                        }}
                        onFocus={handleFocus}
                        inputMode="none"
                      />
                      <div className="input-fixed-unit">
                        {ADD_MENU_FORM.grams}
                      </div>
                    </div>
                  </div>
                  {errors.protein && (
                    <p className="add-menu-form-error">{ADD_MENU_FORM.error}</p>
                  )}
                  <div className="input-field-section">
                    <label>{ADD_MENU_FORM.fat}</label>
                    <div
                      className={`input-with-unit-section ${errors.fat
                        ? 'error-input'
                        : formData?.fat && focusedField === 'fat'
                          ? 'success-input'
                          : ''
                        }`}
                    >
                      <input
                        placeholder="0"
                        name="fat"
                        value={openNumpad && currentInput === "fat" && !isDirty.fat ? '' : (formData.fat ?? '')}
                        onChange={(e) =>
                          handleInputChange('fat', e.target.value)
                        }
                        onClick={() => {
                          toggleNumpad(true, 'fat');
                        }}
                        onFocus={handleFocus}
                        inputMode="none"
                      />
                      <div className="input-fixed-unit">
                        {ADD_MENU_FORM.grams}
                      </div>
                    </div>
                  </div>
                  {errors.fat && (
                    <p className="add-menu-form-error">{ADD_MENU_FORM.error}</p>
                  )}
                </div>
              </div>

              <div className="col-12">
                <div className="dotted-divider"></div>
              </div>

              <div className="col-12">
                <div className="nutrition-info-container">
                  <div className="nutrition-info-title">
                    {ADD_MENU_FORM.header}
                  </div>
                  {NUTRITION_ITEMS.map((item, index) => (
                    <div className="nutrition-info" key={index}>
                      <div className="nutrition-info-left">
                        <img
                          src={`/images/healthAndWellness/foodLog/icons/${item.icon}`}
                        />
                        <span>{item.label}</span>
                      </div>
                      <div className="nutrition-info-right" key={index}>
                        {calculatedValues[index]}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <button
                type="submit"
                className={buttonClass}
                disabled={!isEnabled}
              >
                {ADD_MENU_FORM.btn}
              </button>
            </div>
          </form>
        </div>

        <div className="food-log-numpad-modal">
          <HnWNumpadModal
            onClose={() => toggleNumpad(false)}
            openNumpad={openNumpad}
            onBackgroundClick={handleOKClick}
          >
            {renderNumpad()}
          </HnWNumpadModal>
        </div>
      </div>
    </>
  );
};
export default AddMenu;
